<div class="main">
  <mat-progress-bar *ngIf="loading" [mode]="'indeterminate'"></mat-progress-bar>
 <ng-content></ng-content>
  <div class="load-more-btn" *ngIf="!usePaginate">
    <button mat-raised-button class="load-more" (click)="loadMore()">Load More</button>
  </div>
  <div *ngIf="usePaginate">
    
    <mat-paginator [pageIndex]="page" (page)="getPage($event)" [length]="total" [pageSize]="limit" [pageSizeOptions]="[10, 20, 50, 100]">
    </mat-paginator>
  </div>
</div>
