/**
 * Home Clinic IAM
 * Home Clinic IAM API Documentation
 *
 * The version of the OpenAPI document: 3.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateClockinDto { 
    staffId: number;
    clockInTime?: string;
}

