/**
 * Home Clinic IAM
 * Home Clinic IAM API Documentation
 *
 * The version of the OpenAPI document: 3.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface RolesInfo { 
    action: RolesInfo.ActionEnum;
    userId: string;
    userEmail?: string;
    clientID: string;
    roleNames: Array<string>;
    realmName: string;
}
export namespace RolesInfo {
    export type ActionEnum = 'add' | 'remove';
    export const ActionEnum = {
        Add: 'add' as ActionEnum,
        Remove: 'remove' as ActionEnum
    };
}


