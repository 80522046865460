<div
  class="menu-item"
  [routerLink]="[sideMenuItem.route]"
  routerLinkActive="menu-item-active"
>
<!-- <div class="menu-cover"> -->
  <div>
    <img *ngIf="sideMenuItem.iconType === 'file'" [src]="'assets/icons/' + sideMenuItem.icon" alt="menu icon" />
    <mat-icon *ngIf="sideMenuItem.iconType === 'icon'">{{ sideMenuItem.icon }}</mat-icon>
  </div>
  <div class="menu-title">{{ sideMenuItem.title }}</div>
</div>
<!-- </div> -->