export * from './clockIn.service';
import { ClockInService } from './clockIn.service';
export * from './clockOut.service';
import { ClockOutService } from './clockOut.service';
export * from './default.service';
import { DefaultService } from './default.service';
export * from './organizations.service';
import { OrganizationsService } from './organizations.service';
export * from './staff.service';
import { StaffService } from './staff.service';
export const APIS = [ClockInService, ClockOutService, DefaultService, OrganizationsService, StaffService];
