import { Component, Input } from '@angular/core';
import { TableData } from '../table/table.component';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent {
  @Input() loading = false;
  @Input() tableData!: TableData;
  @Input() loadMore!: Function;
}
