import {NgModule} from '@angular/core';
import {ApolloModule, APOLLO_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import { environment } from 'src/environments/environment';
import { ConfigService } from './configurations.helper';

const uri = environment.GQL_BASE_PATH; // <-- add the URL of the GraphQL server here
export function createApollo(httpLink: HttpLink, configService: ConfigService): ApolloClientOptions<any> {
  console.log("WES", configService.config)
  return {
    link: httpLink.create({uri: configService.config?.frontend_config?.store_api}),
    cache: new InMemoryCache(),
    
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ConfigService],
    },
  ],
})
export class GraphQLModule {}
