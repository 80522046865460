import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SideMenuComponent } from './side-menu.component';
import { SideMenuItemComponent } from './side-menu-item/side-menu-item.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [SideMenuComponent, SideMenuItemComponent],
  imports: [CommonModule, SharedModule],
  exports: [SideMenuComponent],
})
export class SideMenuModule {}
