import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Store } from '../../generated/graphql';

@Injectable({
  providedIn: 'root'
})
export class StoreSelectionService {

  private storesSubject$: BehaviorSubject<Array<Store>>;
  private readonly stores$: Observable<Array<Store>>;

  private selectedStoreSubject$: BehaviorSubject<Store>;
  private readonly selectedStore$: Observable<Store>

  constructor() {
    this.storesSubject$ = new BehaviorSubject<Array<Store>>(undefined as any);
    this.stores$ = this.storesSubject$.asObservable();

    this.selectedStoreSubject$ = new BehaviorSubject<Store>( undefined as any);
    this.selectedStore$ = this.selectedStoreSubject$.asObservable();
  }

  getSelectedStore(): Observable<Store> {
    return this.selectedStore$
  }

  getSelectedStoreValue(): Store {
    return this.selectedStoreSubject$.value
  }

  setActiveStore(store: Store) {
    this.selectedStoreSubject$.next(store);
  }

  getStores(): Observable<Array<Store>> {
    return this.stores$;
  }

  getStoresValue(): Array<Store> {
    return this.storesSubject$.value;
  }

  setStores(stores: Array<Store>) {
    this.storesSubject$.next(stores);
  }
}
