<div class="main">
  <!-- <div (click)="toggleSideMenu()"><mat-icon>menu</mat-icon></div> -->
  <div>
    <img src="assets/images/logo-landscape.png" alt="Organization Logo" />
  </div>

  <div class="menu-items">
    <app-side-menu-item
      *ngFor="let item of sideMenuItems"
      [sideMenuItem]="item"
    ></app-side-menu-item>
    <app-side-menu-item [sideMenuItem]=logoutItem (click)="logout()">

    </app-side-menu-item>
  </div>
</div>
