import { Component, Input } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-stats-card',
  templateUrl: './stats-card.component.html',
  styleUrls: ['./stats-card.component.scss'],
})
export class StatsCardComponent {
  @Input() data!: StatsCardData;
  @Input() index!: number;

  nairaSign = getCurrencySymbol('NGN', 'narrow')
}

export interface StatsCardData {
  main_title: string;
  key: string;
  value: number | string;
  icon: string;
  iconType: 'file' | 'icon';
  width?: 'full' | 'half';
  color?: {
    bg: string;
    item: string;
  };
  isCurrency?: boolean;
  stats: {
    title: string;
    value: string | number;
  }[];
}
