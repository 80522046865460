import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-side-menu-item',
  templateUrl: './side-menu-item.component.html',
  styleUrls: ['./side-menu-item.component.scss'],
})
export class SideMenuItemComponent {
  @Input() sideMenuItem!: SideMenuItem;
}

export interface SideMenuItem {
  title: string;
  icon: string;
  iconType: 'file' | 'icon';
  route?: string;
}
