import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ItemPreviewComponent } from './item-preview.component';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ItemPreviewComponent],
  imports: [CommonModule, MatIconModule],
  exports: [ItemPreviewComponent],
})
export class ItemPreviewModule {}
