<div>
  <div class="product-title title">
    <div>{{ product.product_name }}</div>
    <mat-icon (click)="updateVisibility()">create</mat-icon>
  </div>
  <div class="product-category-row">
    <div
      *ngFor="let category of product.product_categories"
      class="product-category"
    >
      {{ category?.category_name }}
    </div>
  </div>
  <div class="row product-stats">
    <div class="stats-card">
      <div class="stats-title">Cost Price</div>
      <div class="stats-value">
        {{ (inventory?.cost_price || product.product_cost_price) | currency: '₦' }}
      </div>
    </div>
    <div class="stats-card">
      <div class="stats-title">Selling Price</div>
      <div class="stats-value">
        {{ (inventory?.selling_price || product.product_selling_price) | currency: '₦' }}
      </div>
    </div>
    <div *ngIf="inventory" class="stats-card">
      <div class="stats-title">Quantity</div>
      <div class="stats-value">{{ inventory.quantity }}</div>
    </div>
    <div class="stats-card">
      <div class="stats-title">Reorder Level</div>
      <div class="stats-value">
        {{ "< " + (inventory?.reorder_level || product.reorder_level) }}
      </div>
    </div>
  </div>
  <div class="description-title">Item Description</div>
  <div class="description">{{ product.description }}</div>

  <div *ngIf="inventory">
    <div class="row">
      <div><img src="assets/icons/high-alert.png" alt="alert" /></div>
      <div class="expiry-title">Expiry Date</div>
    </div>
    <div class="expiry-date">{{ inventory.expiry_date | date }}</div>
  </div>
</div>
