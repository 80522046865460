/**
 * Home Clinic IAM
 * Home Clinic IAM API Documentation
 *
 * The version of the OpenAPI document: 3.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface UpdateOrganizationDto { 
    id: number;
    org_id?: string;
    org_name?: string;
    org_email?: string;
    org_alt_email?: string;
    org_phone?: string;
    alt_org_phone?: string;
}

