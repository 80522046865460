<ng-container *ngIf="customFormGroup" [formGroup]="customFormGroup">
  <div *ngIf="customType !== 'text-area'">
    <mat-form-field [class]="class" appearance="outline">
      <mat-label>{{ label }}</mat-label>
      <input
        [placeholder]="title"
        [name]="key"
        matInput
        [type]="customType"
        [formControlName]="key"
      />
      <mat-error
        *ngIf="
          customFormGroup.get(key)?.invalid && customFormGroup.get(key)?.touched
        "
      >
        {{ title }} is required
      </mat-error>
    </mat-form-field>
  </div>
  <textarea
    matInput
    *ngIf="customType == 'text-area'"
    [class]="class"
    [placeholder]="title"
    [name]="key"
    matInput
    [formControlName]="key"
    name=""
    id=""
    cols="30"
    rows="10"
  ></textarea>
</ng-container>
