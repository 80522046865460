import { KeycloakService, KeycloakOptions } from 'keycloak-angular';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as dayjs from 'dayjs';
import Swal from 'sweetalert2';
import { firstValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  config?: SalAppConfig;
  code?: string;
  originWithoutProtocol = window.location.origin.replace(
    /^https?:\/\/|\/$/g,
    '',
  );

  constructor(
    private http: HttpClient,
    private snackBar: MatSnackBar,
    private keycloakService: KeycloakService,
  ) {}

  validConfig = () => {
    const storedConfig = JSON.parse(
      localStorage.getItem('sal-config$') || '{}',
    );
    const storedConfigExpiry = Number(
      localStorage.getItem('sal-config-expiry$'),
    );
    const storedConfigOrigin = localStorage.getItem('sal-config-origin$');
    const storedConfigCode = localStorage.getItem('sal-config-code$');

    return {
      config: storedConfig,
      isValid:
        storedConfigOrigin === this.originWithoutProtocol &&
        storedConfig &&
        storedConfigExpiry &&
        dayjs().isBefore(storedConfigExpiry) &&
        storedConfig?.hospital?.sub_domain === storedConfigCode,
    };
  };

  static get config(): SalAppConfig {
    return JSON.parse(localStorage.getItem('sal-config$') || '{}');
  }

  clearConfig(force: boolean = false) {
    if (force) {
      localStorage.removeItem('sal-config$');
      localStorage.removeItem('sal-config-expiry$');
      localStorage.removeItem('sal-config-origin$');
      localStorage.removeItem('sal-config-code$');
      this.keycloakService?.logout();
      return new Promise((resolve) => resolve(true));
    }
    return Swal.fire({
      text: `You are currently logged into ${
        this.config?.hospital?.name ?? this.code ?? 'an organization'
      }. Do you want to keep the settings?`,
      icon: 'warning',
      showDenyButton: true,
      denyButtonText: 'No',
      confirmButtonText: 'Yes',
      showCancelButton: false,
      confirmButtonColor: '#1e88e5',
    }).then((res) => {
      if (res.isDenied) {
        localStorage.removeItem('sal-config$');
        localStorage.removeItem('sal-config-expiry$');
        localStorage.removeItem('sal-config-origin$');
        localStorage.removeItem('sal-config-code$');
      }
      this.keycloakService?.logout();
    });
  }

  async loadConfig(retries = 0, code = ''): Promise<SalAppConfig | void> {
    console.log(this.validConfig());
    if (!this.validConfig()?.config?.hospital?.sub_domain && !code) {
      const _code = await Swal.fire({
        text: 'Enter Hospital Code',
        input: 'text',
      }).then((value) => {
        return value.value;
      });

      if (_code) {
        return await this.loadConfig(0, _code);
      }
    }

    if (this.validConfig()?.config?.hospital?.sub_domain && !code) {
      const change = await Swal.fire({
        text: `You are currently logged into ${
          this.validConfig().config?.hospital?.name ??
          this.code ??
          'an organization'
        }`,
        icon: 'info',
        confirmButtonColor: '#1e88e5',
        confirmButtonText: 'Okay',
        denyButtonText: 'Leave',
        timer: 5000,
        timerProgressBar: true,
        showConfirmButton: true,
        showDenyButton: true,
        // didOpen: () => {
        //   Swal.showLoading();
        // },
      }).then((response) => {
        if (response.isDenied) {
          return true;
        }
        return false;
      });

      if (change) {
        this.clearConfig(true);
        return await this.loadConfig(0);
      } else {
        this.config = this.validConfig().config;
        return this.validConfig().config;
      }
    }

    console.log('LOADING...', code);

    localStorage.setItem('sal-config-origin$', this.originWithoutProtocol);

    if (retries > 4) {
      return;
    }

    retries += 1;

    // if (this.config) return this.config;

    const toReturn = await firstValueFrom(
      this.http.post<[SalAppConfig]>(
        'https://sal-config.apps.savealifetechhub.com/configurations/filter',
        {
          hospital_sub_domain: code,
        },
      ),
    )
      .then((config) => {
        if (!config || !config.length) {
          this.snackBar.open(
            `Could not load config. Retrying in ${retries * 5 * 1000} seconds`,
          );
          setTimeout(
            async () => {
              this.snackBar.dismiss();
              return await this.loadConfig(retries + 1);
            },
            retries * 5 * 1000,
          );
        }
        this.config = config[0];
        localStorage.setItem('sal-config$', JSON.stringify(config[0]));
        localStorage.setItem(
          'sal-config-expiry$',
          new Date(dayjs().add(5, 'minutes').toDate()).getTime().toString(),
        );
        if (code) {
          localStorage.setItem('sal-config-code$', code);
        }
        console.log('CONFIG', this.config);
        window.location.reload();
        return;
      })
      .catch(async (e) => {
        this.snackBar.open(
          `Could not load config. Retrying in ${retries * 5 * 1000} seconds`,
        );
        setTimeout(
          async () => {
            this.snackBar.dismiss();
            return await this.loadConfig(retries + 1);
          },
          retries * 5 * 1000,
        );
      });

    console.log('TORETURN', toReturn);
    return toReturn;
  }
}

export interface SalAppConfig {
  hospital: {
    name: string;
    address: string;
    city: string;
    sub_domain: string;
  };
  frontend_config: {
    title: string;
    care_api: string;
    hmo_api: string;
    production: boolean;
    url: string;
    records_api: string;
    clinicals_api: string;
    clinicals_engine_api: string;
    medical_history_v2_api: string;
    notification_api: string;
    NOTIFICATION_SOCKET_api_V2: string;
    medical_history_api: string;
    medical_history_golang_api: string;
    pharmacy_api: string;
    old_pharmacy_api: string;
    // new_pharmacy_api: string;
    procedure_api: string;
    staff_api: string;
    services_api: string;
    finance_api: string;
    lab_api: string;
    admission_api: string;
    upload_file_api: string;
    store_api: string;
    user_guide_api: string;
    emailUrl: string;
    messaging_api: string;
    socket_api: string;
    keycloak_base_url: string;
    keycloak_realm: string;
    keycloak_client_id: string;
  };
  messaging_mode: 'TWILIO' | 'INVIDEO'; // 'TWILIO' or 'INVIDEO'

  keycloakConfig: {
    clientId: string;
    realm: string;
    url: string;
  };
  initOptions: {
    onLoad: any;
    silentCheckSsoRedirectUri: string;
  };
  ZEGO_APP_ID: number; //356424243,
  tiny_mc: {
    api_key: string;
  };
}

function configGenerator(configService: ConfigService) {
  if (!configService.config) {
    return false;
  }
  if (!configService.config.initOptions) {
    configService.config.initOptions = {} as any;
  }

  configService.config.initOptions.onLoad = 'check-sso';
  configService.config.initOptions.silentCheckSsoRedirectUri =
    window.location.origin + '/assets/silent-check-sso.html';
  const options: KeycloakOptions = {
    config: {
      url: configService.config?.frontend_config?.keycloak_base_url,
      clientId: configService.config?.frontend_config?.keycloak_client_id!,
      realm: configService.config?.frontend_config?.keycloak_realm!,
    },
    initOptions: configService.config?.initOptions,
    enableBearerInterceptor: true,
  };
  return options;
}

export function initializer(
  keycloak: KeycloakService,
  configService: ConfigService,
): () => Promise<boolean> {
  return (): Promise<boolean> => {
    return configService.loadConfig(0).then(() => {
      const options = configGenerator(configService);
      if (!options) {
        return Promise.resolve(false);
      }
      return keycloak.init(options);
    });
  };

  // return (): Promise<any> => {
  //   return keycloak.init(options);
  // };
}

export function environment(key: keyof SalAppConfig) {
  return (configService: ConfigService) => {
    // while(!configService.config?.[key]) {
    //   continue
    // }
    console.log('RETURNING ENV', key, configService.config?.[key]);
    return () => configService.config?.[key];
  };
}
