/**
 * Home Clinic IAM
 * Home Clinic IAM API Documentation
 *
 * The version of the OpenAPI document: 3.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateOrUpdateStaffDto { 
    dept_id: string;
    id?: number;
    fname: string;
    lname: string;
    sex: string;
    designation: string;
    realm: string;
    realmRoles?: Array<string>;
    dept_name: string;
    dept_code?: string;
    email: string;
    d_o_b: string;
    alt_email?: string;
    phone: string;
    alt_phone?: string;
    enabled?: boolean;
    address?: string;
    identity: string;
    password?: string;
    username?: string;
    org_id?: string;
    roles?: Array<string>;
    profile_picture?: string;
}

