import { OnInit, Component, Input, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
})
export class TableComponent implements OnInit, AfterViewInit {
  @Input()
  tableData: TableData = {
    headers: [],
    data: [],
  };

  @Input()
  gridLayout = '1fr 1fr'

  @ViewChild('myTable', { static: false }) myTable!: ElementRef

  constructor(private renderer: Renderer2) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.renderer.setStyle(this.myTable?.nativeElement, 'gridTemplateColumns', this.gridLayout)
  }
}

export interface TableData {
  headers: string[];
  data: {
    $index: number;
    // $serial: number;
    $rowAction?: Function;
    columnData: {
      $isLabel?: boolean;
      $isCurrency?: boolean;
      $color?: string;
      $value?: string | number | null;
      $classList?: string,
      $action?: Function;
    }[];
  }[];
}
