<!-- <div class="table-container">
  <div class="custom-border-1"> 
    <div class="grid-table">
    <div *ngFor="let header of tableData.headers">
        <span class="title">{{ header }}</span>
    </div>
    </div>

  <div >
  <div class="grid-table" *ngFor="let data of tableData.data">
    <div *ngFor="let column of data.columnData">
    <span class="data-title"> {{ column.$value }}</span>
  </div>
  </div>
  </div>
</div> -->

<!-- <table class="custom-border-1">
    <tr>
      <th *ngFor="let header of tableData.headers">{{ header }}</th>
    </tr>
    <tr
      *ngFor="let data of tableData.data"
      (click)="data.$rowAction ? data.$rowAction() : ''"
    >
      <td *ngFor="let column of data.columnData">
        <div
          [ngClass]="
            column.$isLabel
              ? 'label ' + column.$color
              : column.$color + ' no-background'
          "
        >
          {{ column.$value }}
        </div>
      </td>
    </tr>
  </table> -->
<!-- </div> -->


<div class="grid" #myTable>
  <ng-content></ng-content>
</div>