/**
 * Home Clinic IAM
 * Home Clinic IAM API Documentation
 *
 * The version of the OpenAPI document: 3.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface Organization { 
    id: number;
    org_id: string;
    org_name: string;
    org_email: string;
    org_alt_email: string;
    org_phone: string;
    alt_org_phone: string;
    private_key: string;
    public_key: string;
    status: Organization.StatusEnum;
    created: string;
    updated: string;
    deletedAt: string;
}
export namespace Organization {
    export type StatusEnum = 'active' | 'inactive' | 'disabled';
    export const StatusEnum = {
        Active: 'active' as StatusEnum,
        Inactive: 'inactive' as StatusEnum,
        Disabled: 'disabled' as StatusEnum
    };
}


