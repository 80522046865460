import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import {
  Observable,
  startWith,
  map,
  of,
  debounceTime,
  switchMap,
  tap,
} from 'rxjs';
import { ProductCategoriesGQL, ProductCategory } from 'src/generated/graphql';

@Component({
  selector: 'app-product-category-search',
  templateUrl: './product-category-search.component.html',
  styleUrls: ['./product-category-search.component.scss'],
})
export class ProductCategorySearchComponent {
  @Input() product_categories: (Partial<ProductCategory> & { id: number })[] =
    [];
  @Output() updateCategories = new EventEmitter<
    (Partial<ProductCategory> & { id: number })[]
  >();
  // ...
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  categoryCtrl = new FormControl();
  filteredCategories: Observable<ProductCategory[]> = of([]);

  @ViewChild('categoryInput') categoryInput!: ElementRef<HTMLInputElement>;

  constructor(private categoryService: ProductCategoriesGQL) {}

  ngOnInit() {
    this.filteredCategories = this.categoryCtrl.valueChanges.pipe(
      startWith(null),
      debounceTime(300),
      switchMap((text) => {
        return this.categoryService
          .fetch({
            productCategoryFilter: {
              filter: {},
              searchString: text,
              searchField: 'category_name',
              limit: 10,
              page: 0,
            },
          })
          .pipe(
            map(
              (res) =>
                res.data.productCategories
                  .product_categories as ProductCategory[]
            )
          );
      })
    );
  }

  remove(category: Partial<ProductCategory>, index: number): void {
    const foundCategory = this.product_categories.find(
      (data) => category.id === data.id
    );

    if (foundCategory) {
      this.product_categories = this.product_categories.filter(
        (data) => category.id !== data.id
      );
      this.updateCategories.emit(this.product_categories);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (
      !this.product_categories.find((data) => data.id === event.option.value.id)
    ) {
      this.product_categories = [
        ...this.product_categories,
        event.option.value,
      ];
    }
    this.updateCategories.emit(this.product_categories);
    this.categoryInput.nativeElement.value = '';
    this.categoryCtrl.setValue(null);
  }
}
