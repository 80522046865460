import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DashToSpacePipe } from './dash-to-space.pipe';
import { DateStylePipe } from './date-style.pipe';
import { UnderscoreToSpacePipe } from './underscore-to-space.pipe';
import { EnumToArrayPipe } from './enum-to-array.pipe';



@NgModule({
  declarations: [
    DashToSpacePipe,
    DateStylePipe,
    UnderscoreToSpacePipe,
    EnumToArrayPipe
  ],
  imports: [
    CommonModule
  ],
  exports: [
    DashToSpacePipe,
    DateStylePipe,
    UnderscoreToSpacePipe,
    EnumToArrayPipe
  ]
})
export class PipesModule { }
