import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCategorySearchComponent } from './product-category-search.component';
import { SharedModule } from '../shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [ProductCategorySearchComponent],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatChipsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatIconModule
  ],
  exports: [ProductCategorySearchComponent],
})
export class ProductCategorySearchModule {}
