<div
  class="custom-card-1 card-size"
  [ngClass]="data.width === 'full' ? 'card-full-size' : 'card-small'">
  <div class="main-content">
    <div class="top-row">
      <div class="card-title">{{ data.main_title }}</div>
      <div><mat-icon>more_vert</mat-icon></div>
    </div>
    <div *ngIf="data.isCurrency; else notCurrency" class="card-figures">{{ data.value | currency: nairaSign }}</div>
    <ng-template #notCurrency>
      <div class="card-figures">{{ data.value }}</div>
    </ng-template>
  </div>
  <div class="">
    <mat-icon
      [ngStyle]="{
        'background-color': data.color?.bg,
        color: data.color?.item
      }"
      class="bottom-icon bottom-icon-container"
      *ngIf="data.iconType === 'icon'"
      >{{ data.icon }}</mat-icon
    >
    <img
      alt="button"
      class="bottom-icon-container"
      *ngIf="data.iconType === 'file'"
      [src]="'assets/icons/' + data.icon"
      height="10px"
      [ngStyle]="{ 'background-color': data.color?.bg }"
    />
  </div>
</div>
