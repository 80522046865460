import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TableData } from '../table/table.component';
import { PageEvent } from '@angular/material/paginator';

@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
})
export class ListViewComponent {
  @Input() loading = false;
  @Input() tableData!: TableData;
  @Input() loadMore!: Function;
  @Input() usePaginate = false;
  @Input() page = 0;
  @Input() limit = 0;
  @Input() total = 0;
  @Output() paginate: EventEmitter<{ page: number; limit: number }> =
    new EventEmitter();

  getPage($event: PageEvent) {
    this.paginate.emit({
      page: $event.pageIndex,
      limit: $event.pageSize,
    });
  }
}
