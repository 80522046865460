import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Inventory, Product } from 'src/generated/graphql';

@Component({
  selector: 'app-item-preview',
  templateUrl: './item-preview.component.html',
  styleUrls: ['./item-preview.component.scss'],
})
export class ItemPreviewComponent {
  @Input() product!: Product;
  @Input() inventory?: Inventory;
  @Input() isVisible!: boolean;
  @Output() isVisibleChange = new EventEmitter<boolean>();

  updateVisibility() {
    this.isVisibleChange.emit(!this.isVisible);
  }
}
