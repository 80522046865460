<mat-form-field appearance="outline" class="example-chip-list full-width">
  <mat-label>Add or delete Category (e.g #pharmacy, to add label)</mat-label>
  <mat-chip-grid #chipGrid>
    <mat-chip-row color="primary" *ngFor="let category of product_categories; let i = index" [removable]="removable"
      (removed)="remove(category, i)">
      {{ category.category_name }}
      <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
    </mat-chip-row>
  </mat-chip-grid>
  <input placeholder="Find Category..." #categoryInput [matChipInputFor]="chipGrid" [formControl]="categoryCtrl"
    [matAutocomplete]="auto" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
    <mat-option *ngFor="let category of filteredCategories | async" [value]="category">
      {{ category.category_name }}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>