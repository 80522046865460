import { Component, OnInit } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { catchError, finalize, map, of } from 'rxjs';
import { Store, StoresGQL } from '../generated/graphql';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StoreSelectionService } from './services/store-selection.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit{
  title = 'store-management';

  constructor(
    private authService: AuthService,
    private storesServices: StoresGQL,
    private snackBar: MatSnackBar,
    private storeSelectionService: StoreSelectionService
    ) {}

  ngOnInit() {
    this.fetchStores();
  }

  fetchStores() {
    this.storesServices.fetch(
      {
        storesFilter: { limit: 100, page: 0}
      },
      {fetchPolicy: 'no-cache'}
    ).pipe(
      map( (res) => {
        this.storeSelectionService.setStores(res.data.stores.stores as Array<Store>)
        return res.data.stores.stores
      }),
      catchError((err => {
        this.snackBar.open(err.message || 'Something went wrong', 'Close');
        return of([])
      }))).subscribe();
  }
}
