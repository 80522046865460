import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'dateStyle'
})
export class DateStylePipe implements PipeTransform {

  transform(value: string | any ): string {

    if (!value) {
      return ''
    }

    if (dayjs().isAfter(value, 'day')) {
      return 'danger blinker'
    } else if (dayjs(value).diff(dayjs(), 'day') <= 30) {
      return 'warning'
    }
    return '';
  }

}
