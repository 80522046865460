import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-text-input-field',
  templateUrl: './text-input-field.component.html',
  styleUrls: ['./text-input-field.component.scss'],
})
export class TextInputFieldComponent {
  @Input() title!: string;
  @Input() customFormGroup!: FormGroup;
  @Input() key!: string;
  @Input() label!: string;
  @Input() class!: string;
  @Input() customType!: 'text' | 'number' | 'date' | 'text-area';

  constructor() {}
}
