import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type BankDetail = {
  __typename?: 'BankDetail';
  account_name: Scalars['String'];
  account_number?: Maybe<Scalars['String']>;
  actor_id?: Maybe<Scalars['String']>;
  bank_code?: Maybe<Scalars['String']>;
  bank_name?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  supplier: Supplier;
  tenant_id: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type CreateBankDetailInput = {
  account_name: Scalars['String'];
  account_number?: InputMaybe<Scalars['String']>;
  bank_code?: InputMaybe<Scalars['String']>;
  bank_name?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
};

export type CreateGoodsReceiptInput = {
  cost_price: Scalars['Float'];
  date_received: Scalars['DateTime'];
  drug_route?: InputMaybe<RxRoutes>;
  expiry_date: Scalars['DateTime'];
  location?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  product: UpdateProductInput;
  purchase_order_detail: UpdatePurchaseOrderDetailInput;
  quantity: Scalars['Float'];
  received_by_id: Scalars['String'];
  received_by_name: Scalars['String'];
  receiving_store: UpdateStoreInput;
  reorder_level: Scalars['Float'];
  selling_price: Scalars['Float'];
  supplier: UpdateSupplierInput;
};

export type CreateInventoryInput = {
  access_level: Inventory_Access_Level;
  barcode?: InputMaybe<Scalars['String']>;
  brand_name?: InputMaybe<Scalars['String']>;
  cost_price: Scalars['Float'];
  created_by_id: Scalars['String'];
  created_by_name: Scalars['String'];
  drug_route?: InputMaybe<RxRoutes>;
  drug_strength?: InputMaybe<Scalars['String']>;
  expiry_date: Scalars['DateTime'];
  is_drug: Scalars['Boolean'];
  product: UpdateProductInput;
  purchase_order_detail?: InputMaybe<UpdatePurchaseOrderDetailInput>;
  quantity: Scalars['Float'];
  reorder_level?: Scalars['Float'];
  selling_price: Scalars['Float'];
  source_store?: InputMaybe<UpdateStoreInput>;
  store: UpdateStoreInput;
  unit?: InputMaybe<Scalars['String']>;
};

export type CreateItemCategoryInput = {
  category_name: Scalars['String'];
  category_parent?: InputMaybe<Scalars['Int']>;
};

export type CreateOrderDetailInput = {
  /** Example field (placeholder) */
  exampleField: Scalars['Int'];
};

export type CreateOrderInput = {
  /** Example field (placeholder) */
  exampleField: Scalars['Int'];
};

export type CreatePossibleItemInput = {
  item_categories: Array<UpdateItemCategoryInput>;
  item_code: Scalars['String'];
  item_image?: InputMaybe<Scalars['String']>;
  item_name: Scalars['String'];
};

export type CreateProductBrandInput = {
  brand_code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  url?: InputMaybe<Scalars['String']>;
};

export type CreateProductCategoryInput = {
  category_name: Scalars['String'];
  category_parent?: InputMaybe<UpdateProductCategoryInput>;
  description?: InputMaybe<Scalars['String']>;
};

export type CreateProductInput = {
  description?: InputMaybe<Scalars['String']>;
  drug_routes?: InputMaybe<Array<RxRoutes>>;
  image_url?: InputMaybe<Scalars['String']>;
  is_drug?: InputMaybe<Scalars['Boolean']>;
  new_brands?: InputMaybe<Array<Scalars['String']>>;
  number_per_unit: Scalars['Float'];
  product_categories: Array<UpdateProductCategoryInput>;
  product_cost_price: Scalars['Float'];
  /** Name of Product */
  product_name: Scalars['String'];
  product_selling_price: Scalars['Float'];
  reorder_level?: InputMaybe<Scalars['Float']>;
  unit: Scalars['String'];
};

export type CreatePurchaseOrderDetailInput = {
  cost_per_unit: Scalars['Float'];
  product: UpdateProductInput;
  purchase_order: UpdatePurchaseOrderInput;
  quantity: Scalars['Float'];
  unit: UpdateUnitInput;
};

export type CreatePurchaseOrderInput = {
  comment?: InputMaybe<Scalars['String']>;
  expected_delivery_date?: InputMaybe<Scalars['DateTime']>;
  instruction?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  order_date: Scalars['DateTime'];
  purchase_order_details: Array<PurchaseOrderDetailInput>;
  shipping_method?: InputMaybe<Scalars['String']>;
  shipping_terms?: InputMaybe<Scalars['String']>;
  status: PurchaseOrderStatus;
  supplier: UpdateSupplierInput;
};

export type CreateStatInput = {
  /** Example field (placeholder) */
  exampleField: Scalars['Int'];
};

export type CreateStoreInput = {
  about_store?: InputMaybe<Scalars['String']>;
  image_url?: InputMaybe<Scalars['String']>;
  store_location?: InputMaybe<UpdateStoreLocationInput>;
  store_manager_id?: InputMaybe<Scalars['String']>;
  store_manager_name?: InputMaybe<Scalars['String']>;
  store_name: Scalars['String'];
};

export type CreateStoreLocationInput = {
  location_address: Scalars['String'];
  location_city?: InputMaybe<Scalars['String']>;
  location_country?: InputMaybe<Scalars['String']>;
  location_name: Scalars['String'];
  location_state?: InputMaybe<Scalars['String']>;
};

export type CreateSupplierInput = {
  about_supplier?: InputMaybe<Scalars['String']>;
  bank_details?: InputMaybe<Array<UpdateBankDetailInput>>;
  supplier_address: Scalars['String'];
  supplier_city?: InputMaybe<Scalars['String']>;
  supplier_country?: InputMaybe<Scalars['String']>;
  supplier_email: Scalars['String'];
  supplier_name: Scalars['String'];
  supplier_phone: Scalars['String'];
  supplier_state?: InputMaybe<Scalars['String']>;
  supplier_website?: InputMaybe<Scalars['String']>;
  supplier_zip_code?: InputMaybe<Scalars['String']>;
};

export type CreateTransactionInput = {
  cost_per_unit: Scalars['Float'];
  created_by_id: Scalars['String'];
  created_by_name: Scalars['String'];
  customer_name?: InputMaybe<Scalars['String']>;
  destination: UpdateStoreInput;
  hospital_number?: InputMaybe<Scalars['String']>;
  /** Item involved in transaction */
  inventory: UpdateInventoryInput;
  quantity: Scalars['Float'];
  source: UpdateStoreInput;
  tx_ref?: InputMaybe<Scalars['String']>;
  tx_status: TransactionStatus;
  tx_type: TransactionType;
};

export type CreateUnitInput = {
  item_per_unit: Scalars['Float'];
  unit_name: Scalars['String'];
};

export enum ErrorCodeEnum {
  AccessDenied = 'ACCESS_DENIED',
  DuplicateRequest = 'DUPLICATE_REQUEST',
  InternalServerError = 'INTERNAL_SERVER_ERROR',
  InvalidParameter = 'INVALID_PARAMETER',
  InvalidRequest = 'INVALID_REQUEST',
  Unauthorized = 'UNAUTHORIZED'
}

export type ErrorMessage = {
  __typename?: 'ErrorMessage';
  code: ErrorCodeEnum;
  message: Scalars['String'];
};

export type FetchStatsParamsDto = {
  daysBeforeExpiry?: InputMaybe<Scalars['Float']>;
  endDate: Scalars['DateTime'];
  startDate: Scalars['DateTime'];
  store: UpdateStoreInput;
};

export type FetchedGoodsReceipts = {
  __typename?: 'FetchedGoodsReceipts';
  count: Scalars['Int'];
  goodsReceipts: Array<GoodsReceipt>;
};

export type FetchedItemsWithCount = {
  __typename?: 'FetchedItemsWithCount';
  count: Scalars['Int'];
  items: Array<PossibleItem>;
};

export type FilterItemCategoryInput = {
  dateField?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filter: ItemCategoryArgs;
  limit?: Scalars['Int'];
  onlyCount?: Scalars['Boolean'];
  page?: Scalars['Int'];
  /** Value between 0 and 1 to indicate how precise the search should be */
  precision?: InputMaybe<Scalars['Float']>;
  searchField?: InputMaybe<Scalars['String']>;
  searchString?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type FilterPossibleItemsArgs = {
  dateField?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filter?: InputMaybe<PossibleItemFilter>;
  limit?: Scalars['Int'];
  loadCategories?: Scalars['Boolean'];
  onlyCount?: Scalars['Boolean'];
  page?: Scalars['Int'];
  /** Value between 0 and 1 to indicate how precise the search should be */
  precision?: InputMaybe<Scalars['Float']>;
  searchField?: Scalars['String'];
  searchString?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type FilterProductBrandInput = {
  filter: PartialProductBrand;
};

export type FilterProductBrandResponse = {
  __typename?: 'FilterProductBrandResponse';
  error?: Maybe<ErrorMessage>;
  list: Array<ProductBrand>;
  message?: Maybe<ResponseMessageEnum>;
  pagination?: Maybe<Pagination>;
  status?: Maybe<ResponseStatusEnum>;
};

export type FilterScoreCardInput = {
  end_date?: InputMaybe<Scalars['DateTime']>;
  inventory_id?: InputMaybe<Scalars['Float']>;
  start_date?: InputMaybe<Scalars['DateTime']>;
  store_id?: InputMaybe<Scalars['Float']>;
};

export type FilterStoreLocationArgs = {
  dateField?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filter?: StoreLocationArgs;
  limit?: Scalars['Int'];
  onlyCount?: Scalars['Boolean'];
  page?: Scalars['Int'];
  /** Value between 0 and 1 to indicate how precise the search should be */
  precision?: InputMaybe<Scalars['Float']>;
  searchField?: Scalars['String'];
  searchString?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type FilterStoresArgs = {
  dateField?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filter?: StoreArgs;
  limit?: Scalars['Int'];
  loadStoreLocations?: Scalars['Boolean'];
  onlyCount?: Scalars['Boolean'];
  page?: Scalars['Int'];
  /** Value between 0 and 1 to indicate how precise the search should be */
  precision?: InputMaybe<Scalars['Float']>;
  searchField?: Scalars['String'];
  searchString?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type FilterTransactionInput = {
  id: Scalars['Int'];
  load_destination?: InputMaybe<Scalars['Boolean']>;
  load_inventory?: InputMaybe<Scalars['Boolean']>;
  load_source?: InputMaybe<Scalars['Boolean']>;
};

export type FilterTransactionsInput = {
  end_date?: InputMaybe<Scalars['DateTime']>;
  filter?: InputMaybe<TransactionFilterParams>;
  limit: Scalars['Int'];
  load_destination?: InputMaybe<Scalars['Boolean']>;
  load_inventory?: InputMaybe<Scalars['Boolean']>;
  load_source?: InputMaybe<Scalars['Boolean']>;
  page: Scalars['Int'];
  searchField?: InputMaybe<Scalars['String']>;
  searchString?: InputMaybe<Scalars['String']>;
  start_date?: InputMaybe<Scalars['DateTime']>;
  /** if store_id is provided, the values of source and destination stores will be overiden. it will be used to find either source or destination having same id as store_id */
  store_id?: InputMaybe<Scalars['Int']>;
};

export type FilterTransactionsStatsInput = {
  store_id: Scalars['Float'];
  /** options - today, this_month, last_three_month, this_year, all */
  timePeriod?: TimePeriod;
};

export type FilteredInventories = {
  __typename?: 'FilteredInventories';
  count: Scalars['Int'];
  list: Array<Inventory>;
};

export type FilteredInventoriesV2 = {
  __typename?: 'FilteredInventoriesV2';
  error: ErrorMessage;
  list: Array<PartialInventory>;
  message: ResponseMessageEnum;
  pagination: Pagination;
  status: ResponseStatusEnum;
};

export type FilteredProductCategories = {
  __typename?: 'FilteredProductCategories';
  count: Scalars['Int'];
  product_categories: Array<ProductCategory>;
};

export type FilteredProducts = {
  __typename?: 'FilteredProducts';
  count: Scalars['Int'];
  products: Array<Product>;
};

export type FilteredPurchaseOrders = {
  __typename?: 'FilteredPurchaseOrders';
  count: Scalars['Int'];
  purchase_orders: Array<PurchaseOrder>;
};

export type FilteredScoreCards = {
  __typename?: 'FilteredScoreCards';
  totalIn: Scalars['Int'];
  totalOut: Scalars['Int'];
  totalRemainingInStore: Scalars['Int'];
};

export type FilteredSuppliers = {
  __typename?: 'FilteredSuppliers';
  count: Scalars['Int'];
  suppliers: Array<Supplier>;
};

export type FilteredTransactions = {
  __typename?: 'FilteredTransactions';
  count: Scalars['Int'];
  list: Array<PartialTransaction>;
};

export type FilteredTransactionsStatistics = {
  __typename?: 'FilteredTransactionsStatistics';
  expired_items: Scalars['Int'];
  expiring_items: Scalars['Int'];
  number_of_items_exceeded_reorder_level: Scalars['Int'];
  total_amount_of_transactions: Scalars['Int'];
  total_items: Scalars['Int'];
  total_number_of_transactions: Scalars['Int'];
  total_value_of_items: Scalars['Int'];
};

export type FilteredUnits = {
  __typename?: 'FilteredUnits';
  count: Scalars['Int'];
  list: Array<Unit>;
};

export type GoodsReceipt = {
  __typename?: 'GoodsReceipt';
  actor_id?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  date_received: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  location?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  product: Product;
  purchase_order_detail: PurchaseOrderDetail;
  quantity: Scalars['Float'];
  received_by_id: Scalars['String'];
  received_by_name: Scalars['String'];
  receiving_store?: Maybe<Store>;
  supplier: Supplier;
  tenant_id: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type GoodsReceiptsFilter = {
  dateField?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filter: UpdateGoodsReceiptInput;
  limit?: Scalars['Int'];
  loadProduct?: Scalars['Boolean'];
  loadPurchaseOrder?: Scalars['Boolean'];
  loadReceivingStore?: Scalars['Boolean'];
  loadSupplier?: Scalars['Boolean'];
  onlyCount?: Scalars['Boolean'];
  page?: Scalars['Int'];
  /** Value between 0 and 1 to indicate how precise the search should be */
  precision?: InputMaybe<Scalars['Float']>;
  searchField?: InputMaybe<Scalars['String']>;
  searchString?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export enum Inventory_Access_Level {
  Hidden = 'HIDDEN',
  Private = 'PRIVATE',
  Public = 'PUBLIC'
}

export type Inventory = {
  __typename?: 'Inventory';
  access_level: Inventory_Access_Level;
  actor_id?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  brand_name?: Maybe<Scalars['String']>;
  cost_price: Scalars['Float'];
  created: Scalars['DateTime'];
  created_by_id?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  drug_route?: Maybe<RxRoutes>;
  drug_strength?: Maybe<Scalars['String']>;
  expiry_date: Scalars['DateTime'];
  goods_receipts?: Maybe<Array<GoodsReceipt>>;
  id: Scalars['Int'];
  is_drug?: Maybe<Scalars['Boolean']>;
  order_details?: Maybe<Array<OrderDetail>>;
  product: Product;
  product_brand: ProductBrand;
  purchase_order_detail?: Maybe<PurchaseOrderDetail>;
  quantity: Scalars['Float'];
  reorder_level: Scalars['Float'];
  selling_price: Scalars['Float'];
  store: Store;
  tenant_id: Scalars['String'];
  transactions?: Maybe<Array<Transaction>>;
  unit?: Maybe<Scalars['String']>;
  updated: Scalars['DateTime'];
};

export type InventoryFilter = {
  dateField?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filter: InventoryFilterFields;
  limit?: Scalars['Int'];
  onlyCount?: Scalars['Boolean'];
  page?: Scalars['Int'];
  /** Value between 0 and 1 to indicate how precise the search should be */
  precision?: InputMaybe<Scalars['Float']>;
  searchField?: Scalars['String'];
  searchString?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type InventoryFilterFields = {
  product_id?: InputMaybe<Scalars['Float']>;
  store_id?: InputMaybe<Scalars['Float']>;
};

export type InventoryFilterV2 = {
  dateField?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filter: PartialInventoryFilter;
  /** optional numeric value of limit of data to be returned */
  limit?: InputMaybe<Scalars['Float']>;
  /** optional enum value of order type  eg. ASC | DESC */
  order?: InputMaybe<Scalars['String']>;
  /** optional string value of field named  */
  orderBy?: InputMaybe<Scalars['String']>;
  /** optional numeric value of page of data to be returned */
  page?: InputMaybe<Scalars['Float']>;
  /** optional array value of field names to be used for relationship popultations */
  relations?: InputMaybe<Array<Scalars['String']>>;
  /** field to use when searching */
  searchField?: InputMaybe<Scalars['String']>;
  /** Enforce accuracy. Value ranges from 0 - 1 */
  searchPrecision?: InputMaybe<Scalars['Float']>;
  /** optional string to be used for search */
  searchString?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type ItemCategory = {
  __typename?: 'ItemCategory';
  actor_id?: Maybe<Scalars['String']>;
  category_name?: Maybe<Scalars['String']>;
  category_parent?: Maybe<Scalars['Int']>;
  created: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  tenant_id: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type ItemCategoryArgs = {
  category_name?: InputMaybe<Scalars['String']>;
  category_parent?: InputMaybe<Scalars['Int']>;
};

export type MultipleStoreToStoreTransferDto = {
  created_by_id: Scalars['String'];
  created_by_name: Scalars['String'];
  destination_store_id: Scalars['Float'];
  items: Array<SingleItem>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createBankDetail: BankDetail;
  createGoodsReceipt: GoodsReceipt;
  createInventory: Inventory;
  createItemCategory: ItemCategory;
  createOrder: Order;
  createOrderDetail: OrderDetail;
  createPossibleItem: PossibleItem;
  createProduct: Product;
  createProductBrand: ProductBrand;
  createProductCategory: ProductCategory;
  createPurchaseOrder: PurchaseOrder;
  createPurchaseOrderDetail: PurchaseOrderDetail;
  createStat: Stat;
  createStore: Store;
  createStoreLocation: StoreLocation;
  createSupplier: Supplier;
  createTransaction: Transaction;
  createUnit: Unit;
  multipleStoreToStoreTransfer: StoreToStoreTransferResponse;
  removeBankDetail: BankDetail;
  removeGoodsReceipt: GoodsReceipt;
  removeInventory: Inventory;
  removeItemCategory: ItemCategory;
  removeOrder: Order;
  removeOrderDetail: OrderDetail;
  removePossibleItem: PossibleItem;
  removeProduct: Product;
  removeProductBrand: ProductBrand;
  removeProductCategory: ProductCategory;
  removePurchaseOrder: PurchaseOrder;
  removePurchaseOrderDetail: PurchaseOrderDetail;
  removeStoreLocation: StoreLocation;
  removeSupplier: Supplier;
  removeUnit: Unit;
  storeToStoreTransfer: StoreToStoreTransferResponse;
  updateBankDetail: BankDetail;
  updateGoodsReceipt: GoodsReceipt;
  updateInventory: Inventory;
  updateItemCategory: ItemCategory;
  updateOrder: Order;
  updateOrderDetail: OrderDetail;
  updatePossibleItem: PossibleItem;
  updateProduct: Product;
  updateProductBrand: ProductBrand;
  updateProductCategory: ProductCategory;
  updatePurchaseOrder: PurchaseOrder;
  updatePurchaseOrderDetail: PurchaseOrderDetail;
  updateStore: Store;
  updateStoreLocation: StoreLocation;
  updateSupplier: Supplier;
  updateUnit: Unit;
};


export type MutationCreateBankDetailArgs = {
  createBankDetailInput: CreateBankDetailInput;
};


export type MutationCreateGoodsReceiptArgs = {
  createGoodsReceiptInput: CreateGoodsReceiptInput;
};


export type MutationCreateInventoryArgs = {
  createInventoryInput: CreateInventoryInput;
};


export type MutationCreateItemCategoryArgs = {
  createItemCategoryInput: CreateItemCategoryInput;
};


export type MutationCreateOrderArgs = {
  createOrderInput: CreateOrderInput;
};


export type MutationCreateOrderDetailArgs = {
  createOrderDetailInput: CreateOrderDetailInput;
};


export type MutationCreatePossibleItemArgs = {
  createPossibleItemInput: CreatePossibleItemInput;
};


export type MutationCreateProductArgs = {
  createProductInput: CreateProductInput;
};


export type MutationCreateProductBrandArgs = {
  createProductBrandInput: CreateProductBrandInput;
};


export type MutationCreateProductCategoryArgs = {
  createProductCategoryInput: CreateProductCategoryInput;
};


export type MutationCreatePurchaseOrderArgs = {
  createPurchaseOrderInput: CreatePurchaseOrderInput;
};


export type MutationCreatePurchaseOrderDetailArgs = {
  createPurchaseOrderDetailInput: CreatePurchaseOrderDetailInput;
};


export type MutationCreateStatArgs = {
  createStatInput: CreateStatInput;
};


export type MutationCreateStoreArgs = {
  createStoreInput: CreateStoreInput;
};


export type MutationCreateStoreLocationArgs = {
  createStoreLocationInput: CreateStoreLocationInput;
};


export type MutationCreateSupplierArgs = {
  createSupplierInput: CreateSupplierInput;
};


export type MutationCreateTransactionArgs = {
  createTransactionInput: CreateTransactionInput;
};


export type MutationCreateUnitArgs = {
  createUnitInput: CreateUnitInput;
};


export type MutationMultipleStoreToStoreTransferArgs = {
  storeTransferData: MultipleStoreToStoreTransferDto;
};


export type MutationRemoveBankDetailArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveGoodsReceiptArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveInventoryArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveItemCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveOrderArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveOrderDetailArgs = {
  id: Scalars['Int'];
};


export type MutationRemovePossibleItemArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveProductArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveProductBrandArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveProductCategoryArgs = {
  id: Scalars['Int'];
};


export type MutationRemovePurchaseOrderArgs = {
  id: Scalars['Int'];
};


export type MutationRemovePurchaseOrderDetailArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveStoreLocationArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveSupplierArgs = {
  id: Scalars['Int'];
};


export type MutationRemoveUnitArgs = {
  id: Scalars['Int'];
};


export type MutationStoreToStoreTransferArgs = {
  storeTransferData: StoreToStoreTransferDto;
};


export type MutationUpdateBankDetailArgs = {
  updateBankDetailInput: UpdateBankDetailInput;
};


export type MutationUpdateGoodsReceiptArgs = {
  updateGoodsReceiptInput: UpdateGoodsReceiptInput;
};


export type MutationUpdateInventoryArgs = {
  updateInventoryInput: UpdateInventoryInput;
};


export type MutationUpdateItemCategoryArgs = {
  updateItemCategoryInput: UpdateItemCategoryInput;
};


export type MutationUpdateOrderArgs = {
  updateOrderInput: UpdateOrderInput;
};


export type MutationUpdateOrderDetailArgs = {
  updateOrderDetailInput: UpdateOrderDetailInput;
};


export type MutationUpdatePossibleItemArgs = {
  updatePossibleItemInput: UpdatePossibleItemInput;
};


export type MutationUpdateProductArgs = {
  updateProductInput: UpdateProductInput;
};


export type MutationUpdateProductBrandArgs = {
  updateProductBrandInput: UpdateProductBrandInput;
};


export type MutationUpdateProductCategoryArgs = {
  updateProductCategoryInput: UpdateProductCategoryInput;
};


export type MutationUpdatePurchaseOrderArgs = {
  updatePurchaseOrderInput: UpdatePurchaseOrderInput;
};


export type MutationUpdatePurchaseOrderDetailArgs = {
  updatePurchaseOrderDetailInput: UpdatePurchaseOrderDetailInput;
};


export type MutationUpdateStoreArgs = {
  updateStoreInput: UpdateStoreInput;
};


export type MutationUpdateStoreLocationArgs = {
  updateStoreLocationInput: UpdateStoreLocationInput;
};


export type MutationUpdateSupplierArgs = {
  updateSupplierInput: UpdateSupplierInput;
};


export type MutationUpdateUnitArgs = {
  updateUnitInput: UpdateUnitInput;
};

export type Order = {
  __typename?: 'Order';
  actor_id?: Maybe<Scalars['String']>;
  adress?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  first_name?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  last_name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  tenant_id: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type OrderDetail = {
  __typename?: 'OrderDetail';
  actor_id?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  tenant_id: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type Pagination = {
  __typename?: 'Pagination';
  items_per_page: Scalars['Float'];
  page: Scalars['Float'];
  total_items: Scalars['Float'];
  total_pages: Scalars['Float'];
};

export type PartialInventory = {
  __typename?: 'PartialInventory';
  access_level?: Maybe<Inventory_Access_Level>;
  actor_id?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
  brand_name?: Maybe<Scalars['String']>;
  cost_price?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['DateTime']>;
  created_by_id?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  drug_route?: Maybe<RxRoutes>;
  drug_strength?: Maybe<Scalars['String']>;
  expiry_date?: Maybe<Scalars['DateTime']>;
  goods_receipts?: Maybe<Array<GoodsReceipt>>;
  id?: Maybe<Scalars['Int']>;
  is_drug?: Maybe<Scalars['Boolean']>;
  order_details?: Maybe<Array<OrderDetail>>;
  product?: Maybe<Product>;
  product_brand?: Maybe<ProductBrand>;
  purchase_order_detail?: Maybe<PurchaseOrderDetail>;
  quantity?: Maybe<Scalars['Float']>;
  reorder_level?: Maybe<Scalars['Float']>;
  selling_price?: Maybe<Scalars['Float']>;
  store?: Maybe<Store>;
  tenant_id?: Maybe<Scalars['String']>;
  transactions?: Maybe<Array<Transaction>>;
  unit?: Maybe<Scalars['String']>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type PartialInventoryFilter = {
  access_level?: InputMaybe<Inventory_Access_Level>;
  barcode?: InputMaybe<Scalars['String']>;
  brand_name?: InputMaybe<Scalars['String']>;
  cost_price?: InputMaybe<Scalars['Float']>;
  created_by_id?: InputMaybe<Scalars['String']>;
  created_by_name?: InputMaybe<Scalars['String']>;
  drug_route?: InputMaybe<RxRoutes>;
  drug_strength?: InputMaybe<Scalars['String']>;
  expiry_date?: InputMaybe<Scalars['DateTime']>;
  id?: InputMaybe<Scalars['Int']>;
  is_drug?: InputMaybe<Scalars['Boolean']>;
  last_modified_by_id?: InputMaybe<Scalars['String']>;
  last_modified_by_name?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<UpdateProductInput>;
  purchase_order_detail?: InputMaybe<UpdatePurchaseOrderDetailInput>;
  quantity?: InputMaybe<Scalars['Float']>;
  reorder_level?: InputMaybe<Scalars['Float']>;
  selling_price?: InputMaybe<Scalars['Float']>;
  source_store?: InputMaybe<UpdateStoreInput>;
  store?: InputMaybe<UpdateStoreInput>;
  unit?: InputMaybe<Scalars['String']>;
};

export type PartialProductBrand = {
  brand_code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type PartialTransaction = {
  __typename?: 'PartialTransaction';
  actor_id?: Maybe<Scalars['String']>;
  /** Cost per unit */
  cost_per_unit?: Maybe<Scalars['Float']>;
  created?: Maybe<Scalars['DateTime']>;
  created_by_id?: Maybe<Scalars['String']>;
  created_by_name?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  destination?: Maybe<Store>;
  /** Destination Quantity After Transaction */
  dqat?: Maybe<Scalars['Float']>;
  hospital_number?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  inventory?: Maybe<Inventory>;
  quantity?: Maybe<Scalars['Float']>;
  received_by_id?: Maybe<Scalars['String']>;
  received_by_name?: Maybe<Scalars['String']>;
  source?: Maybe<Store>;
  /** Source Quantity After Transaction */
  sqat?: Maybe<Scalars['Float']>;
  tenant_id?: Maybe<Scalars['String']>;
  tx_ref?: Maybe<Scalars['String']>;
  tx_status?: Maybe<TransactionStatus>;
  tx_type?: Maybe<TransactionType>;
  updated?: Maybe<Scalars['DateTime']>;
};

export type PossibleItem = {
  __typename?: 'PossibleItem';
  actor_id?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  item_categories: Array<ItemCategory>;
  item_code: Scalars['String'];
  item_image?: Maybe<Scalars['String']>;
  item_name: Scalars['String'];
  tenant_id: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type PossibleItemFilter = {
  item_name?: InputMaybe<Scalars['String']>;
};

export type Product = {
  __typename?: 'Product';
  actor_id?: Maybe<Scalars['String']>;
  brands?: Maybe<Array<ProductBrand>>;
  created: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  dosage_routes?: Maybe<Array<RxRoutes>>;
  id: Scalars['Int'];
  image_url?: Maybe<Scalars['String']>;
  is_drug?: Maybe<Scalars['Boolean']>;
  number_per_unit: Scalars['Float'];
  product_categories?: Maybe<Array<ProductCategory>>;
  /** Product code */
  product_code?: Maybe<Scalars['String']>;
  product_cost_price: Scalars['Float'];
  /** Name of Product */
  product_name: Scalars['String'];
  product_selling_price: Scalars['Float'];
  reorder_level?: Maybe<Scalars['Float']>;
  tenant_id: Scalars['String'];
  unit: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type ProductBrand = {
  __typename?: 'ProductBrand';
  actor_id?: Maybe<Scalars['String']>;
  brand_code?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  is_active?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  manufacturer_code?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  product: Product;
  tenant_id: Scalars['String'];
  updated: Scalars['DateTime'];
  url?: Maybe<Scalars['String']>;
};

export type ProductCategory = {
  __typename?: 'ProductCategory';
  actor_id?: Maybe<Scalars['String']>;
  category_name?: Maybe<Scalars['String']>;
  category_parent?: Maybe<ProductCategory>;
  created: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  tenant_id: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type ProductCategoryFilter = {
  dateField?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filter: ProductCategoryFilterFields;
  limit?: Scalars['Int'];
  loadParentCategory?: Scalars['Boolean'];
  onlyCount?: Scalars['Boolean'];
  page?: Scalars['Int'];
  /** Value between 0 and 1 to indicate how precise the search should be */
  precision?: InputMaybe<Scalars['Float']>;
  searchField?: InputMaybe<Scalars['String']>;
  searchString?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type ProductCategoryFilterFields = {
  category_name?: InputMaybe<Scalars['String']>;
  category_parent?: InputMaybe<UpdateProductCategoryInput>;
  description?: InputMaybe<Scalars['String']>;
};

export type ProductFilter = {
  dateField?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filter: ProductFilterFields;
  limit?: Scalars['Int'];
  loadCategory?: Scalars['Boolean'];
  onlyCount?: Scalars['Boolean'];
  page?: Scalars['Int'];
  /** Value between 0 and 1 to indicate how precise the search should be */
  precision?: InputMaybe<Scalars['Float']>;
  searchField?: Scalars['String'];
  searchString?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type ProductFilterFields = {
  number_per_unit?: InputMaybe<Scalars['Float']>;
  product_category?: InputMaybe<UpdateProductCategoryInput>;
  product_cost?: InputMaybe<Scalars['Float']>;
  /** Name of Product */
  product_name?: InputMaybe<Scalars['String']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type PurchaseOrder = {
  __typename?: 'PurchaseOrder';
  actor_id?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  expected_delivery_date?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  instruction?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  order_date: Scalars['DateTime'];
  purchase_order_details?: Maybe<Array<PurchaseOrderDetail>>;
  shipping_method?: Maybe<Scalars['String']>;
  shipping_terms?: Maybe<Scalars['String']>;
  status: PurchaseOrderStatus;
  supplier: Supplier;
  tenant_id: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type PurchaseOrderDetail = {
  __typename?: 'PurchaseOrderDetail';
  actor_id?: Maybe<Scalars['String']>;
  cost_per_unit: Scalars['Float'];
  created: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  product: Product;
  purchase_order: PurchaseOrder;
  quantity: Scalars['Float'];
  tenant_id: Scalars['String'];
  unit?: Maybe<Unit>;
  updated: Scalars['DateTime'];
};

export type PurchaseOrderDetailInput = {
  cost_per_unit: Scalars['Float'];
  product: UpdateProductInput;
  quantity: Scalars['Float'];
  unit: UpdateUnitInput;
};

export type PurchaseOrderFilter = {
  dateField?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filter?: PurchaseOrderFilterFields;
  limit?: Scalars['Int'];
  loadPurchaseOrderDetails?: Scalars['Boolean'];
  loadSupplier?: Scalars['Boolean'];
  onlyCount?: Scalars['Boolean'];
  page?: Scalars['Int'];
  /** Value between 0 and 1 to indicate how precise the search should be */
  precision?: InputMaybe<Scalars['Float']>;
  searchField?: InputMaybe<Scalars['String']>;
  searchString?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type PurchaseOrderFilterFields = {
  status?: InputMaybe<PurchaseOrderStatus>;
  supplier?: InputMaybe<Scalars['Int']>;
};

export enum PurchaseOrderStatus {
  Cancelled = 'CANCELLED',
  Pending = 'PENDING',
  Received = 'RECEIVED'
}

export type Query = {
  __typename?: 'Query';
  bankDetail: BankDetail;
  fetchStats: StatsResult;
  filterProductBrandResponse: FilterProductBrandResponse;
  inventories: FilteredInventories;
  inventoriesV2: FilteredInventoriesV2;
  inventory: Inventory;
  itemCategories: Array<ItemCategory>;
  itemCategory: ItemCategory;
  listGoodsReceipt: FetchedGoodsReceipts;
  order: Order;
  orderDetail: OrderDetail;
  orderDetails: Array<OrderDetail>;
  possibleItem: PossibleItem;
  possibleItems: FetchedItemsWithCount;
  product: Product;
  productBrand: ProductBrand;
  productCategories: FilteredProductCategories;
  productCategory: ProductCategory;
  products: FilteredProducts;
  purchaseOrder: PurchaseOrder;
  purchaseOrderDetail: PurchaseOrderDetail;
  purchaseOrderDetails: Array<PurchaseOrderDetail>;
  purchaseOrders: FilteredPurchaseOrders;
  scorecard: FilteredScoreCards;
  singleGoodsReceipt: GoodsReceipt;
  store: Store;
  storeLocation: StoreLocation;
  storeLocations: StoreLocationsAndCount;
  stores: StoresWithCount;
  supplier: Supplier;
  suppliersList: FilteredSuppliers;
  transaction: PartialTransaction;
  transactions: FilteredTransactions;
  transactionsStatistics: FilteredTransactionsStatistics;
  unit: Unit;
  units: FilteredUnits;
};


export type QueryBankDetailArgs = {
  id: Scalars['Int'];
};


export type QueryFetchStatsArgs = {
  statsFilter: FetchStatsParamsDto;
};


export type QueryFilterProductBrandResponseArgs = {
  productBrandsFilterArgs: FilterProductBrandInput;
};


export type QueryInventoriesArgs = {
  inventoryFilterArgs: InventoryFilter;
};


export type QueryInventoriesV2Args = {
  inventoryFilterArgs: InventoryFilterV2;
};


export type QueryInventoryArgs = {
  id: Scalars['Int'];
};


export type QueryItemCategoriesArgs = {
  filterItemCategoryInput: FilterItemCategoryInput;
};


export type QueryItemCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryListGoodsReceiptArgs = {
  goodsReceiptsFilter: GoodsReceiptsFilter;
};


export type QueryOrderArgs = {
  id: Scalars['Int'];
};


export type QueryOrderDetailArgs = {
  id: Scalars['Int'];
};


export type QueryPossibleItemArgs = {
  id: Scalars['Int'];
};


export type QueryPossibleItemsArgs = {
  filterObj: FilterPossibleItemsArgs;
};


export type QueryProductArgs = {
  id: Scalars['Int'];
};


export type QueryProductBrandArgs = {
  id: Scalars['Int'];
};


export type QueryProductCategoriesArgs = {
  productCategoryFilterArgs: ProductCategoryFilter;
};


export type QueryProductCategoryArgs = {
  id: Scalars['Int'];
};


export type QueryProductsArgs = {
  productsFilterArgs: ProductFilter;
};


export type QueryPurchaseOrderArgs = {
  id: Scalars['Int'];
};


export type QueryPurchaseOrderDetailArgs = {
  id: Scalars['Int'];
};


export type QueryPurchaseOrdersArgs = {
  purchaseOrderFilter: PurchaseOrderFilter;
};


export type QueryScorecardArgs = {
  filterTransactionsInput: FilterScoreCardInput;
};


export type QuerySingleGoodsReceiptArgs = {
  id: Scalars['Int'];
};


export type QueryStoreArgs = {
  id: Scalars['Int'];
};


export type QueryStoreLocationArgs = {
  id: Scalars['Int'];
};


export type QueryStoreLocationsArgs = {
  filterStoreLocationArgs: FilterStoreLocationArgs;
};


export type QueryStoresArgs = {
  filterStoresArgs: FilterStoresArgs;
};


export type QuerySupplierArgs = {
  id: Scalars['Int'];
};


export type QuerySuppliersListArgs = {
  suppliersFilter: SupplierFilter;
};


export type QueryTransactionArgs = {
  filterTransactionInput: FilterTransactionInput;
};


export type QueryTransactionsArgs = {
  filterTransactionsInput: FilterTransactionsInput;
};


export type QueryTransactionsStatisticsArgs = {
  filterTransactionsStatisticsInput: FilterTransactionsStatsInput;
};


export type QueryUnitArgs = {
  id: Scalars['Int'];
};


export type QueryUnitsArgs = {
  unitsFilterArgs: UnitFilter;
};

export enum ResponseMessageEnum {
  RequestFailed = 'REQUEST_FAILED',
  RequestInProgress = 'REQUEST_IN_PROGRESS',
  RequestSuccessful = 'REQUEST_SUCCESSFUL'
}

export enum ResponseStatusEnum {
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export enum RxRoutes {
  Buccal = 'Buccal',
  Capsule = 'Capsule',
  Drops = 'Drops',
  Gutt = 'Gutt',
  Inhaled = 'Inhaled',
  Injection = 'Injection',
  Intramuscular = 'Intramuscular',
  Intranasal = 'Intranasal',
  Intraocular = 'Intraocular',
  Intrathecal = 'Intrathecal',
  Intraurethral = 'Intraurethral',
  Intravenous = 'Intravenous',
  Lozenges = 'Lozenges',
  Nasal = 'Nasal',
  Ocular = 'Ocular',
  Otic = 'Otic',
  Pessary = 'Pessary',
  Powder = 'Powder',
  Rectal = 'Rectal',
  Satchet = 'Satchet',
  Subcutaneous = 'Subcutaneous',
  Sublingual = 'Sublingual',
  Suppository = 'Suppository',
  Suspension = 'Suspension',
  Syrup = 'Syrup',
  Tablet = 'Tablet',
  Topical = 'Topical',
  Transdermal = 'Transdermal',
  Urethral = 'Urethral',
  Vaginal = 'Vaginal'
}

export type SingleItem = {
  inventory_id: Scalars['Float'];
  quantity: Scalars['Float'];
};

export type Stat = {
  __typename?: 'Stat';
  expired_items: Scalars['Int'];
  expiring_items: Scalars['Int'];
  total_worth: Scalars['Float'];
};

export type StatsResult = {
  __typename?: 'StatsResult';
  numberOfExpiredProducts?: Maybe<Scalars['Float']>;
  numberOfExpiringProducts?: Maybe<Scalars['Float']>;
  numberOfItems?: Maybe<Scalars['Float']>;
  numberOfItemsBelowReorder?: Maybe<Scalars['Float']>;
  numberOfTransactions?: Maybe<Scalars['Float']>;
  valueOfItems?: Maybe<Scalars['Float']>;
};

export type Store = {
  __typename?: 'Store';
  about_store?: Maybe<Scalars['String']>;
  actor_id?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  image_url?: Maybe<Scalars['String']>;
  inventory: Array<Inventory>;
  store_location?: Maybe<StoreLocation>;
  store_manager_id?: Maybe<Scalars['String']>;
  store_manager_name?: Maybe<Scalars['String']>;
  store_name: Scalars['String'];
  tenant_id: Scalars['String'];
  transactions_as_destination: Array<Transaction>;
  transactions_as_source: Array<Transaction>;
  updated: Scalars['DateTime'];
};

export type StoreArgs = {
  store_name?: InputMaybe<Scalars['String']>;
};

export type StoreLocation = {
  __typename?: 'StoreLocation';
  actor_id?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  location_address: Scalars['String'];
  location_city?: Maybe<Scalars['String']>;
  location_country?: Maybe<Scalars['String']>;
  location_name?: Maybe<Scalars['String']>;
  location_state?: Maybe<Scalars['String']>;
  stores?: Maybe<Array<Store>>;
  tenant_id: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type StoreLocationArgs = {
  location_address?: InputMaybe<Scalars['String']>;
  location_city?: InputMaybe<Scalars['String']>;
  location_country?: InputMaybe<Scalars['String']>;
  location_name?: InputMaybe<Scalars['String']>;
  location_state?: InputMaybe<Scalars['String']>;
};

export type StoreLocationsAndCount = {
  __typename?: 'StoreLocationsAndCount';
  count: Scalars['Int'];
  store_locations: Array<StoreLocation>;
};

export type StoreToStoreTransferDto = {
  created_by_id: Scalars['String'];
  created_by_name: Scalars['String'];
  destination_store_id: Scalars['Float'];
  inventory_id: Scalars['Float'];
  quantity: Scalars['Float'];
};

export type StoreToStoreTransferResponse = {
  __typename?: 'StoreToStoreTransferResponse';
  error?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type StoresWithCount = {
  __typename?: 'StoresWithCount';
  count: Scalars['Int'];
  stores: Array<Store>;
};

export type Supplier = {
  __typename?: 'Supplier';
  about_supplier?: Maybe<Scalars['String']>;
  actor_id?: Maybe<Scalars['String']>;
  bank_details?: Maybe<Array<BankDetail>>;
  created: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  supplier_address: Scalars['String'];
  supplier_city?: Maybe<Scalars['String']>;
  supplier_country?: Maybe<Scalars['String']>;
  supplier_email: Scalars['String'];
  supplier_name: Scalars['String'];
  supplier_phone?: Maybe<Scalars['String']>;
  supplier_state?: Maybe<Scalars['String']>;
  supplier_website?: Maybe<Scalars['String']>;
  supplier_zip_code?: Maybe<Scalars['String']>;
  tenant_id: Scalars['String'];
  updated: Scalars['DateTime'];
};

export type SupplierFilter = {
  dateField?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filter: SupplierFilterFields;
  limit?: Scalars['Int'];
  onlyCount?: Scalars['Boolean'];
  page?: Scalars['Int'];
  /** Value between 0 and 1 to indicate how precise the search should be */
  precision?: InputMaybe<Scalars['Float']>;
  searchField?: Scalars['String'];
  searchString?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type SupplierFilterFields = {
  supplier_email?: InputMaybe<Scalars['String']>;
  supplier_phone?: InputMaybe<Scalars['String']>;
};

export enum TimePeriod {
  All = 'ALL',
  LastThreeMonth = 'LAST_THREE_MONTH',
  ThisMonth = 'THIS_MONTH',
  ThisYear = 'THIS_YEAR',
  Today = 'TODAY'
}

export type Transaction = {
  __typename?: 'Transaction';
  actor_id?: Maybe<Scalars['String']>;
  /** Cost per unit */
  cost_per_unit: Scalars['Float'];
  created: Scalars['DateTime'];
  created_by_id: Scalars['String'];
  created_by_name: Scalars['String'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  destination?: Maybe<Store>;
  /** Destination Quantity After Transaction */
  dqat: Scalars['Float'];
  hospital_number: Scalars['String'];
  id: Scalars['Int'];
  inventory: Inventory;
  quantity: Scalars['Float'];
  received_by_id: Scalars['String'];
  received_by_name: Scalars['String'];
  source?: Maybe<Store>;
  /** Source Quantity After Transaction */
  sqat: Scalars['Float'];
  tenant_id: Scalars['String'];
  tx_ref: Scalars['String'];
  tx_status: TransactionStatus;
  tx_type: TransactionType;
  updated: Scalars['DateTime'];
};

export type TransactionFilterParams = {
  cost_per_unit?: InputMaybe<Scalars['Float']>;
  created_by_id?: InputMaybe<Scalars['String']>;
  created_by_name?: InputMaybe<Scalars['String']>;
  customer_name?: InputMaybe<Scalars['String']>;
  destination?: InputMaybe<UpdateStoreInput>;
  hospital_number?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  /** Item involved in transaction */
  inventory?: InputMaybe<UpdateInventoryInput>;
  quantity?: InputMaybe<Scalars['Float']>;
  source?: InputMaybe<UpdateStoreInput>;
  tx_ref?: InputMaybe<Scalars['String']>;
  tx_status?: InputMaybe<TransactionStatus>;
  tx_type?: InputMaybe<TransactionType>;
};

export enum TransactionStatus {
  Failed = 'FAILED',
  Success = 'SUCCESS'
}

export enum TransactionType {
  Purchase = 'PURCHASE',
  Sale = 'SALE',
  Transfer = 'TRANSFER'
}

export type Unit = {
  __typename?: 'Unit';
  actor_id?: Maybe<Scalars['String']>;
  created: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  item_per_unit?: Maybe<Scalars['Float']>;
  tenant_id: Scalars['String'];
  unit_name?: Maybe<Scalars['String']>;
  updated: Scalars['DateTime'];
};

export type UnitFilter = {
  dateField?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['DateTime']>;
  filter: UnitFilterFields;
  limit?: Scalars['Int'];
  onlyCount?: Scalars['Boolean'];
  page?: Scalars['Int'];
  /** Value between 0 and 1 to indicate how precise the search should be */
  precision?: InputMaybe<Scalars['Float']>;
  searchField?: Scalars['String'];
  searchString?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
};

export type UnitFilterFields = {
  item_per_unit?: InputMaybe<Scalars['Float']>;
  unit_name?: InputMaybe<Scalars['String']>;
};

export type UpdateBankDetailInput = {
  account_name?: InputMaybe<Scalars['String']>;
  account_number?: InputMaybe<Scalars['String']>;
  bank_code?: InputMaybe<Scalars['String']>;
  bank_name?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  note?: InputMaybe<Scalars['String']>;
};

export type UpdateGoodsReceiptInput = {
  cost_price?: InputMaybe<Scalars['Float']>;
  date_received?: InputMaybe<Scalars['DateTime']>;
  drug_route?: InputMaybe<RxRoutes>;
  expiry_date?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  location?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<UpdateProductInput>;
  purchase_order_detail?: InputMaybe<UpdatePurchaseOrderDetailInput>;
  quantity?: InputMaybe<Scalars['Float']>;
  received_by_id?: InputMaybe<Scalars['String']>;
  received_by_name?: InputMaybe<Scalars['String']>;
  receiving_store?: InputMaybe<UpdateStoreInput>;
  reorder_level?: InputMaybe<Scalars['Float']>;
  selling_price?: InputMaybe<Scalars['Float']>;
  supplier?: InputMaybe<UpdateSupplierInput>;
};

export type UpdateInventoryInput = {
  access_level?: InputMaybe<Inventory_Access_Level>;
  barcode?: InputMaybe<Scalars['String']>;
  brand_name?: InputMaybe<Scalars['String']>;
  cost_price?: InputMaybe<Scalars['Float']>;
  created_by_id?: InputMaybe<Scalars['String']>;
  created_by_name?: InputMaybe<Scalars['String']>;
  drug_route?: InputMaybe<RxRoutes>;
  drug_strength?: InputMaybe<Scalars['String']>;
  expiry_date?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  is_drug?: InputMaybe<Scalars['Boolean']>;
  last_modified_by_id?: InputMaybe<Scalars['String']>;
  last_modified_by_name?: InputMaybe<Scalars['String']>;
  product?: InputMaybe<UpdateProductInput>;
  purchase_order_detail?: InputMaybe<UpdatePurchaseOrderDetailInput>;
  quantity?: InputMaybe<Scalars['Float']>;
  reorder_level?: InputMaybe<Scalars['String']>;
  selling_price?: InputMaybe<Scalars['Float']>;
  source_store?: InputMaybe<UpdateStoreInput>;
  store?: InputMaybe<UpdateStoreInput>;
  unit?: InputMaybe<Scalars['String']>;
};

export type UpdateItemCategoryInput = {
  category_name?: InputMaybe<Scalars['String']>;
  category_parent?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
};

export type UpdateOrderDetailInput = {
  /** Example field (placeholder) */
  exampleField?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
};

export type UpdateOrderInput = {
  /** Example field (placeholder) */
  exampleField?: InputMaybe<Scalars['Int']>;
  id: Scalars['Int'];
};

export type UpdatePossibleItemInput = {
  id: Scalars['Int'];
  item_categories?: InputMaybe<Array<UpdateItemCategoryInput>>;
  item_code?: InputMaybe<Scalars['String']>;
  item_image?: InputMaybe<Scalars['String']>;
  item_name?: InputMaybe<Scalars['String']>;
};

export type UpdateProductBrandInput = {
  brand_code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  is_active?: InputMaybe<Scalars['Boolean']>;
  logo?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  url?: InputMaybe<Scalars['String']>;
};

export type UpdateProductCategoryInput = {
  category_name?: InputMaybe<Scalars['String']>;
  category_parent?: InputMaybe<UpdateProductCategoryInput>;
  description?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
};

export type UpdateProductInput = {
  brands?: InputMaybe<Array<UpdateProductBrandInput>>;
  description?: InputMaybe<Scalars['String']>;
  drug_routes?: InputMaybe<Array<RxRoutes>>;
  id: Scalars['Int'];
  image_url?: InputMaybe<Scalars['String']>;
  is_drug?: InputMaybe<Scalars['Boolean']>;
  new_brands?: InputMaybe<Array<Scalars['String']>>;
  number_per_unit?: InputMaybe<Scalars['Float']>;
  product_categories?: InputMaybe<Array<UpdateProductCategoryInput>>;
  product_cost_price?: InputMaybe<Scalars['Float']>;
  /** Name of Product */
  product_name?: InputMaybe<Scalars['String']>;
  product_selling_price?: InputMaybe<Scalars['Float']>;
  reorder_level?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<Scalars['String']>;
};

export type UpdatePurchaseOrderDetailInput = {
  cost_per_unit?: InputMaybe<Scalars['Float']>;
  id: Scalars['Int'];
  product?: InputMaybe<UpdateProductInput>;
  purchase_order?: InputMaybe<UpdatePurchaseOrderInput>;
  quantity?: InputMaybe<Scalars['Float']>;
  unit?: InputMaybe<UpdateUnitInput>;
};

export type UpdatePurchaseOrderInput = {
  comment?: InputMaybe<Scalars['String']>;
  expected_delivery_date?: InputMaybe<Scalars['DateTime']>;
  id: Scalars['Int'];
  instruction?: InputMaybe<Scalars['String']>;
  note?: InputMaybe<Scalars['String']>;
  order_date?: InputMaybe<Scalars['DateTime']>;
  purchase_order_details?: InputMaybe<Array<PurchaseOrderDetailInput>>;
  shipping_method?: InputMaybe<Scalars['String']>;
  shipping_terms?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<PurchaseOrderStatus>;
  supplier?: InputMaybe<UpdateSupplierInput>;
};

export type UpdateStoreInput = {
  about_store?: InputMaybe<Scalars['String']>;
  id: Scalars['Int'];
  image_url?: InputMaybe<Scalars['String']>;
  store_location?: InputMaybe<UpdateStoreLocationInput>;
  store_manager_id?: InputMaybe<Scalars['String']>;
  store_manager_name?: InputMaybe<Scalars['String']>;
  store_name?: InputMaybe<Scalars['String']>;
};

export type UpdateStoreLocationInput = {
  id: Scalars['Int'];
  location_address?: InputMaybe<Scalars['String']>;
  location_city?: InputMaybe<Scalars['String']>;
  location_country?: InputMaybe<Scalars['String']>;
  location_name?: InputMaybe<Scalars['String']>;
  location_state?: InputMaybe<Scalars['String']>;
};

export type UpdateSupplierInput = {
  about_supplier?: InputMaybe<Scalars['String']>;
  bank_details?: InputMaybe<Array<UpdateBankDetailInput>>;
  id: Scalars['Int'];
  supplier_address?: InputMaybe<Scalars['String']>;
  supplier_city?: InputMaybe<Scalars['String']>;
  supplier_country?: InputMaybe<Scalars['String']>;
  supplier_email?: InputMaybe<Scalars['String']>;
  supplier_name?: InputMaybe<Scalars['String']>;
  supplier_phone?: InputMaybe<Scalars['String']>;
  supplier_state?: InputMaybe<Scalars['String']>;
  supplier_website?: InputMaybe<Scalars['String']>;
  supplier_zip_code?: InputMaybe<Scalars['String']>;
};

export type UpdateUnitInput = {
  id: Scalars['Int'];
  item_per_unit?: InputMaybe<Scalars['Float']>;
  unit_name?: InputMaybe<Scalars['String']>;
};

export type CreateInventoryMutationVariables = Exact<{
  inventoryCreateArgs: CreateInventoryInput;
}>;


export type CreateInventoryMutation = { __typename?: 'Mutation', createInventory: { __typename?: 'Inventory', id: number } };

export type UpdateInventoryMutationVariables = Exact<{
  inventoryUpdateArgs: UpdateInventoryInput;
}>;


export type UpdateInventoryMutation = { __typename?: 'Mutation', updateInventory: { __typename?: 'Inventory', id: number } };

export type CreateProductCategoryMutationVariables = Exact<{
  createProductCategoryInput: CreateProductCategoryInput;
}>;


export type CreateProductCategoryMutation = { __typename?: 'Mutation', createProductCategory: { __typename?: 'ProductCategory', id: number, category_name?: string | null } };

export type UpdateProductCategoryMutationVariables = Exact<{
  updateProductCategoryInput: UpdateProductCategoryInput;
}>;


export type UpdateProductCategoryMutation = { __typename?: 'Mutation', updateProductCategory: { __typename?: 'ProductCategory', id: number, updated: any } };

export type CreateProductMutationVariables = Exact<{
  createProductInput: CreateProductInput;
}>;


export type CreateProductMutation = { __typename?: 'Mutation', createProduct: { __typename?: 'Product', id: number, product_name: string } };

export type UpdateProductMutationVariables = Exact<{
  updateProductInput: UpdateProductInput;
}>;


export type UpdateProductMutation = { __typename?: 'Mutation', updateProduct: { __typename?: 'Product', id: number, product_name: string } };

export type CreatePurchaseOrderMutationVariables = Exact<{
  createPurchaseOrderInput: CreatePurchaseOrderInput;
}>;


export type CreatePurchaseOrderMutation = { __typename?: 'Mutation', createPurchaseOrder: { __typename?: 'PurchaseOrder', id: number } };

export type CreateStoreLocationMutationVariables = Exact<{
  createStoreLocationInput: CreateStoreLocationInput;
}>;


export type CreateStoreLocationMutation = { __typename?: 'Mutation', createStoreLocation: { __typename?: 'StoreLocation', id: number, location_name?: string | null } };

export type UpdateStoreLocationMutationVariables = Exact<{
  updateStoreLocationInput: UpdateStoreLocationInput;
}>;


export type UpdateStoreLocationMutation = { __typename?: 'Mutation', updateStoreLocation: { __typename?: 'StoreLocation', location_name?: string | null } };

export type MultipleStoreToStoreTransferMutationVariables = Exact<{
  storeToStoreArgs: MultipleStoreToStoreTransferDto;
}>;


export type MultipleStoreToStoreTransferMutation = { __typename?: 'Mutation', multipleStoreToStoreTransfer: { __typename?: 'StoreToStoreTransferResponse', message: string, error?: string | null } };

export type CreateStoreMutationVariables = Exact<{
  createStoreInput: CreateStoreInput;
}>;


export type CreateStoreMutation = { __typename?: 'Mutation', createStore: { __typename?: 'Store', id: number, store_name: string } };

export type UpdateStoreMutationVariables = Exact<{
  updateStoreInput: UpdateStoreInput;
}>;


export type UpdateStoreMutation = { __typename?: 'Mutation', updateStore: { __typename?: 'Store', id: number, store_name: string } };

export type CreateSupplierMutationVariables = Exact<{
  supplier: CreateSupplierInput;
}>;


export type CreateSupplierMutation = { __typename?: 'Mutation', createSupplier: { __typename?: 'Supplier', id: number } };

export type UpdateSupplierMutationVariables = Exact<{
  supplier: UpdateSupplierInput;
}>;


export type UpdateSupplierMutation = { __typename?: 'Mutation', updateSupplier: { __typename?: 'Supplier', id: number } };

export type RemoveSupplierMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveSupplierMutation = { __typename?: 'Mutation', removeSupplier: { __typename?: 'Supplier', id: number } };

export type CreateUnitMutationVariables = Exact<{
  createUnitInput: CreateUnitInput;
}>;


export type CreateUnitMutation = { __typename?: 'Mutation', createUnit: { __typename?: 'Unit', id: number, unit_name?: string | null } };

export type UpdateUnitMutationVariables = Exact<{
  updateUnitInput: UpdateUnitInput;
}>;


export type UpdateUnitMutation = { __typename?: 'Mutation', updateUnit: { __typename?: 'Unit', id: number, unit_name?: string | null } };

export type FetchStatsQueryVariables = Exact<{
  statsFilter: FetchStatsParamsDto;
}>;


export type FetchStatsQuery = { __typename?: 'Query', fetchStats: { __typename?: 'StatsResult', valueOfItems?: number | null, numberOfItems?: number | null, numberOfTransactions?: number | null, numberOfExpiringProducts?: number | null, numberOfExpiredProducts?: number | null, numberOfItemsBelowReorder?: number | null } };

export type FetchInventoriesQueryVariables = Exact<{
  inventoryFilter: InventoryFilter;
}>;


export type FetchInventoriesQuery = { __typename?: 'Query', inventories: { __typename?: 'FilteredInventories', count: number, list: Array<{ __typename?: 'Inventory', id: number, created: any, updated: any, drug_strength?: string | null, drug_route?: RxRoutes | null, is_drug?: boolean | null, brand_name?: string | null, unit?: string | null, cost_price: number, selling_price: number, quantity: number, expiry_date: any, reorder_level: number, access_level: Inventory_Access_Level, product: { __typename?: 'Product', id: number, product_name: string, reorder_level?: number | null, description?: string | null, product_categories?: Array<{ __typename?: 'ProductCategory', id: number, category_name?: string | null, description?: string | null }> | null }, store: { __typename?: 'Store', id: number, store_name: string, store_manager_name?: string | null, store_location?: { __typename?: 'StoreLocation', id: number, location_name?: string | null } | null } }> } };

export type FetchInventoriesV2QueryVariables = Exact<{
  inventoryFilter: InventoryFilterV2;
}>;


export type FetchInventoriesV2Query = { __typename?: 'Query', inventoriesV2: { __typename?: 'FilteredInventoriesV2', status: ResponseStatusEnum, message: ResponseMessageEnum, list: Array<{ __typename?: 'PartialInventory', id?: number | null, created?: any | null, updated?: any | null, drug_strength?: string | null, drug_route?: RxRoutes | null, is_drug?: boolean | null, brand_name?: string | null, unit?: string | null, cost_price?: number | null, selling_price?: number | null, quantity?: number | null, expiry_date?: any | null, reorder_level?: number | null, access_level?: Inventory_Access_Level | null, product?: { __typename?: 'Product', id: number, product_name: string, reorder_level?: number | null, description?: string | null, product_categories?: Array<{ __typename?: 'ProductCategory', id: number, category_name?: string | null, description?: string | null }> | null } | null, store?: { __typename?: 'Store', id: number, store_name: string, store_manager_name?: string | null, store_location?: { __typename?: 'StoreLocation', id: number, location_name?: string | null } | null } | null }>, pagination: { __typename?: 'Pagination', page: number, total_pages: number, total_items: number, items_per_page: number } } };

export type FetchSingleItemQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FetchSingleItemQuery = { __typename?: 'Query', inventory: { __typename?: 'Inventory', id: number, tenant_id: string, actor_id?: string | null, created: any, updated: any, drug_route?: RxRoutes | null, drug_strength?: string | null, is_drug?: boolean | null, brand_name?: string | null, unit?: string | null, quantity: number, expiry_date: any, reorder_level: number, selling_price: number, cost_price: number, barcode?: string | null, access_level: Inventory_Access_Level, product: { __typename?: 'Product', id: number, product_name: string, reorder_level?: number | null, description?: string | null, product_categories?: Array<{ __typename?: 'ProductCategory', id: number, category_name?: string | null, description?: string | null }> | null } } };

export type ProductCategoriesQueryVariables = Exact<{
  productCategoryFilter: ProductCategoryFilter;
}>;


export type ProductCategoriesQuery = { __typename?: 'Query', productCategories: { __typename?: 'FilteredProductCategories', count: number, product_categories: Array<{ __typename?: 'ProductCategory', id: number, category_name?: string | null, description?: string | null, category_parent?: { __typename?: 'ProductCategory', category_name?: string | null, description?: string | null } | null }> } };

export type ProductCategoryQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProductCategoryQuery = { __typename?: 'Query', productCategory: { __typename?: 'ProductCategory', category_name?: string | null, description?: string | null, category_parent?: { __typename?: 'ProductCategory', category_name?: string | null, description?: string | null } | null } };

export type FetchProductQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FetchProductQuery = { __typename?: 'Query', product: { __typename?: 'Product', id: number, actor_id?: string | null, created: any, updated: any, product_name: string, product_cost_price: number, product_selling_price: number, unit: string, number_per_unit: number, reorder_level?: number | null, image_url?: string | null, description?: string | null, product_categories?: Array<{ __typename?: 'ProductCategory', id: number, category_name?: string | null }> | null } };

export type ProductsQueryVariables = Exact<{
  productsFilter: ProductFilter;
}>;


export type ProductsQuery = { __typename?: 'Query', products: { __typename?: 'FilteredProducts', count: number, products: Array<{ __typename?: 'Product', id: number, product_name: string, product_cost_price: number, product_selling_price: number, unit: string, number_per_unit: number, reorder_level?: number | null, created: any, updated: any, image_url?: string | null, description?: string | null, product_categories?: Array<{ __typename?: 'ProductCategory', id: number, category_name?: string | null }> | null, brands?: Array<{ __typename?: 'ProductBrand', id: number, name: string }> | null }> } };

export type FetchPurchaseOrderDetailQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FetchPurchaseOrderDetailQuery = { __typename?: 'Query', purchaseOrderDetail: { __typename?: 'PurchaseOrderDetail', id: number, quantity: number, cost_per_unit: number, product: { __typename?: 'Product', id: number } } };

export type FetchPurchaseOrderQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FetchPurchaseOrderQuery = { __typename?: 'Query', purchaseOrder: { __typename?: 'PurchaseOrder', id: number, tenant_id: string, actor_id?: string | null, created: any, updated: any, deletedAt?: any | null, order_date: any, expected_delivery_date?: any | null, note?: string | null, shipping_method?: string | null, shipping_terms?: string | null, instruction?: string | null, status: PurchaseOrderStatus, supplier: { __typename?: 'Supplier', id: number, supplier_name: string, supplier_address: string, supplier_email: string, supplier_country?: string | null, supplier_state?: string | null, supplier_city?: string | null, supplier_zip_code?: string | null, supplier_phone?: string | null }, purchase_order_details?: Array<{ __typename?: 'PurchaseOrderDetail', id: number, quantity: number, cost_per_unit: number, unit?: { __typename?: 'Unit', id: number, unit_name?: string | null } | null, product: { __typename?: 'Product', id: number, product_name: string } }> | null } };

export type FetchPurchaseOrdersQueryVariables = Exact<{
  purchaseOrderFilter: PurchaseOrderFilter;
}>;


export type FetchPurchaseOrdersQuery = { __typename?: 'Query', purchaseOrders: { __typename?: 'FilteredPurchaseOrders', purchase_orders: Array<{ __typename?: 'PurchaseOrder', id: number, tenant_id: string, actor_id?: string | null, created: any, updated: any, deletedAt?: any | null, order_date: any, expected_delivery_date?: any | null, status: PurchaseOrderStatus, supplier: { __typename?: 'Supplier', id: number, supplier_name: string }, purchase_order_details?: Array<{ __typename?: 'PurchaseOrderDetail', id: number }> | null }> } };

export type StoreLocationsQueryVariables = Exact<{
  storeLocationFilter: FilterStoreLocationArgs;
}>;


export type StoreLocationsQuery = { __typename?: 'Query', storeLocations: { __typename?: 'StoreLocationsAndCount', count: number, store_locations: Array<{ __typename?: 'StoreLocation', id: number, location_name?: string | null, location_address: string, location_state?: string | null, location_city?: string | null, created: any, updated: any }> } };

export type StoreLocationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StoreLocationQuery = { __typename?: 'Query', storeLocation: { __typename?: 'StoreLocation', id: number, location_name?: string | null, location_address: string, location_state?: string | null, location_city?: string | null, created: any, updated: any } };

export type StoreQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type StoreQuery = { __typename?: 'Query', store: { __typename?: 'Store', id: number, actor_id?: string | null, created: any, updated: any, store_name: string, store_manager_name?: string | null, store_manager_id?: string | null, store_location?: { __typename?: 'StoreLocation', id: number, location_name?: string | null, location_address: string } | null } };

export type StoresQueryVariables = Exact<{
  storesFilter: FilterStoresArgs;
}>;


export type StoresQuery = { __typename?: 'Query', stores: { __typename?: 'StoresWithCount', count: number, stores: Array<{ __typename?: 'Store', id: number, actor_id?: string | null, created: any, updated: any, store_name: string, store_manager_name?: string | null, store_manager_id?: string | null, store_location?: { __typename?: 'StoreLocation', id: number, location_name?: string | null, location_address: string } | null }> } };

export type SupplierQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type SupplierQuery = { __typename?: 'Query', supplier: { __typename?: 'Supplier', id: number, tenant_id: string, actor_id?: string | null, created: any, updated: any, deletedAt?: any | null, supplier_name: string, supplier_address: string, supplier_email: string, supplier_phone?: string | null, supplier_website?: string | null, supplier_city?: string | null, supplier_country?: string | null, supplier_zip_code?: string | null, supplier_state?: string | null, bank_details?: Array<{ __typename?: 'BankDetail', id: number, tenant_id: string, actor_id?: string | null, created: any, updated: any, deletedAt?: any | null, account_name: string, account_number?: string | null, bank_name?: string | null, bank_code?: string | null, note?: string | null }> | null } };

export type FetchSupplierQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FetchSupplierQuery = { __typename?: 'Query', supplier: { __typename?: 'Supplier', id: number, tenant_id: string, actor_id?: string | null, created: any, updated: any, deletedAt?: any | null, supplier_name: string, supplier_address: string, supplier_email: string, supplier_phone?: string | null, supplier_website?: string | null, supplier_city?: string | null, supplier_country?: string | null, supplier_zip_code?: string | null, about_supplier?: string | null, supplier_state?: string | null, bank_details?: Array<{ __typename?: 'BankDetail', id: number, tenant_id: string, actor_id?: string | null, created: any, updated: any, deletedAt?: any | null, account_name: string, account_number?: string | null, bank_name?: string | null, bank_code?: string | null, note?: string | null }> | null } };

export type SuppliersQueryVariables = Exact<{
  suppliersFilter: SupplierFilter;
}>;


export type SuppliersQuery = { __typename?: 'Query', suppliersList: { __typename?: 'FilteredSuppliers', count: number, suppliers: Array<{ __typename?: 'Supplier', id: number, tenant_id: string, actor_id?: string | null, created: any, updated: any, supplier_name: string, supplier_address: string, supplier_email: string, supplier_phone?: string | null, supplier_website?: string | null, supplier_city?: string | null, supplier_country?: string | null, supplier_zip_code?: string | null, about_supplier?: string | null, supplier_state?: string | null }> } };

export type TransactionQueryVariables = Exact<{
  transactionFilter: FilterTransactionInput;
}>;


export type TransactionQuery = { __typename?: 'Query', transaction: { __typename?: 'PartialTransaction', id?: number | null, tenant_id?: string | null, actor_id?: string | null, created?: any | null, updated?: any | null, deletedAt?: any | null, cost_per_unit?: number | null, quantity?: number | null, created_by_id?: string | null, created_by_name?: string | null, tx_type?: TransactionType | null, tx_ref?: string | null, tx_status?: TransactionStatus | null, sqat?: number | null, dqat?: number | null, inventory?: { __typename?: 'Inventory', id: number, product: { __typename?: 'Product', id: number, product_name: string, product_cost_price: number, product_selling_price: number } } | null, source?: { __typename?: 'Store', id: number, store_name: string, store_manager_name?: string | null, store_manager_id?: string | null, tenant_id: string } | null, destination?: { __typename?: 'Store', id: number, store_name: string, store_manager_name?: string | null, store_manager_id?: string | null, tenant_id: string } | null } };

export type TransactionsQueryVariables = Exact<{
  transactionsFilter: FilterTransactionsInput;
}>;


export type TransactionsQuery = { __typename?: 'Query', transactions: { __typename?: 'FilteredTransactions', count: number, list: Array<{ __typename?: 'PartialTransaction', id?: number | null, tenant_id?: string | null, actor_id?: string | null, created?: any | null, updated?: any | null, deletedAt?: any | null, cost_per_unit?: number | null, quantity?: number | null, created_by_id?: string | null, created_by_name?: string | null, tx_type?: TransactionType | null, tx_ref?: string | null, tx_status?: TransactionStatus | null, sqat?: number | null, dqat?: number | null, inventory?: { __typename?: 'Inventory', id: number, product: { __typename?: 'Product', id: number, product_name: string, product_cost_price: number, product_selling_price: number } } | null, source?: { __typename?: 'Store', id: number, store_name: string, store_manager_name?: string | null, store_manager_id?: string | null, tenant_id: string } | null, destination?: { __typename?: 'Store', id: number, store_name: string, store_manager_name?: string | null, store_manager_id?: string | null, tenant_id: string } | null }> } };

export type TransactionsStatisticsQueryVariables = Exact<{
  filterTransactionsStatisticsInput: FilterTransactionsStatsInput;
}>;


export type TransactionsStatisticsQuery = { __typename?: 'Query', transactionsStatistics: { __typename?: 'FilteredTransactionsStatistics', total_number_of_transactions: number, total_amount_of_transactions: number, expired_items: number, expiring_items: number, number_of_items_exceeded_reorder_level: number, total_value_of_items: number, total_items: number } };

export type UnitQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UnitQuery = { __typename?: 'Query', unit: { __typename?: 'Unit', id: number, tenant_id: string, actor_id?: string | null, created: any, updated: any, deletedAt?: any | null, unit_name?: string | null, item_per_unit?: number | null, description?: string | null } };

export type UnitsQueryVariables = Exact<{
  unitsFilter: UnitFilter;
}>;


export type UnitsQuery = { __typename?: 'Query', units: { __typename?: 'FilteredUnits', list: Array<{ __typename?: 'Unit', id: number, tenant_id: string, actor_id?: string | null, created: any, updated: any, deletedAt?: any | null, unit_name?: string | null, item_per_unit?: number | null, description?: string | null }> } };

export const CreateInventoryDocument = gql`
    mutation CreateInventory($inventoryCreateArgs: CreateInventoryInput!) {
  createInventory(createInventoryInput: $inventoryCreateArgs) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateInventoryGQL extends Apollo.Mutation<CreateInventoryMutation, CreateInventoryMutationVariables> {
    override document = CreateInventoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateInventoryDocument = gql`
    mutation UpdateInventory($inventoryUpdateArgs: UpdateInventoryInput!) {
  updateInventory(updateInventoryInput: $inventoryUpdateArgs) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateInventoryGQL extends Apollo.Mutation<UpdateInventoryMutation, UpdateInventoryMutationVariables> {
    override document = UpdateInventoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProductCategoryDocument = gql`
    mutation CreateProductCategory($createProductCategoryInput: CreateProductCategoryInput!) {
  createProductCategory(createProductCategoryInput: $createProductCategoryInput) {
    id
    category_name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProductCategoryGQL extends Apollo.Mutation<CreateProductCategoryMutation, CreateProductCategoryMutationVariables> {
    override document = CreateProductCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProductCategoryDocument = gql`
    mutation UpdateProductCategory($updateProductCategoryInput: UpdateProductCategoryInput!) {
  updateProductCategory(updateProductCategoryInput: $updateProductCategoryInput) {
    id
    updated
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProductCategoryGQL extends Apollo.Mutation<UpdateProductCategoryMutation, UpdateProductCategoryMutationVariables> {
    override document = UpdateProductCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateProductDocument = gql`
    mutation CreateProduct($createProductInput: CreateProductInput!) {
  createProduct(createProductInput: $createProductInput) {
    id
    product_name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateProductGQL extends Apollo.Mutation<CreateProductMutation, CreateProductMutationVariables> {
    override document = CreateProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateProductDocument = gql`
    mutation UpdateProduct($updateProductInput: UpdateProductInput!) {
  updateProduct(updateProductInput: $updateProductInput) {
    id
    product_name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateProductGQL extends Apollo.Mutation<UpdateProductMutation, UpdateProductMutationVariables> {
    override document = UpdateProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreatePurchaseOrderDocument = gql`
    mutation CreatePurchaseOrder($createPurchaseOrderInput: CreatePurchaseOrderInput!) {
  createPurchaseOrder(createPurchaseOrderInput: $createPurchaseOrderInput) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreatePurchaseOrderGQL extends Apollo.Mutation<CreatePurchaseOrderMutation, CreatePurchaseOrderMutationVariables> {
    override document = CreatePurchaseOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStoreLocationDocument = gql`
    mutation CreateStoreLocation($createStoreLocationInput: CreateStoreLocationInput!) {
  createStoreLocation(createStoreLocationInput: $createStoreLocationInput) {
    id
    location_name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStoreLocationGQL extends Apollo.Mutation<CreateStoreLocationMutation, CreateStoreLocationMutationVariables> {
    override document = CreateStoreLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStoreLocationDocument = gql`
    mutation UpdateStoreLocation($updateStoreLocationInput: UpdateStoreLocationInput!) {
  updateStoreLocation(updateStoreLocationInput: $updateStoreLocationInput) {
    location_name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStoreLocationGQL extends Apollo.Mutation<UpdateStoreLocationMutation, UpdateStoreLocationMutationVariables> {
    override document = UpdateStoreLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const MultipleStoreToStoreTransferDocument = gql`
    mutation MultipleStoreToStoreTransfer($storeToStoreArgs: MultipleStoreToStoreTransferDto!) {
  multipleStoreToStoreTransfer(storeTransferData: $storeToStoreArgs) {
    message
    error
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class MultipleStoreToStoreTransferGQL extends Apollo.Mutation<MultipleStoreToStoreTransferMutation, MultipleStoreToStoreTransferMutationVariables> {
    override document = MultipleStoreToStoreTransferDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateStoreDocument = gql`
    mutation CreateStore($createStoreInput: CreateStoreInput!) {
  createStore(createStoreInput: $createStoreInput) {
    id
    store_name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateStoreGQL extends Apollo.Mutation<CreateStoreMutation, CreateStoreMutationVariables> {
    override document = CreateStoreDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateStoreDocument = gql`
    mutation UpdateStore($updateStoreInput: UpdateStoreInput!) {
  updateStore(updateStoreInput: $updateStoreInput) {
    id
    store_name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateStoreGQL extends Apollo.Mutation<UpdateStoreMutation, UpdateStoreMutationVariables> {
    override document = UpdateStoreDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateSupplierDocument = gql`
    mutation CreateSupplier($supplier: CreateSupplierInput!) {
  createSupplier(createSupplierInput: $supplier) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateSupplierGQL extends Apollo.Mutation<CreateSupplierMutation, CreateSupplierMutationVariables> {
    override document = CreateSupplierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateSupplierDocument = gql`
    mutation UpdateSupplier($supplier: UpdateSupplierInput!) {
  updateSupplier(updateSupplierInput: $supplier) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateSupplierGQL extends Apollo.Mutation<UpdateSupplierMutation, UpdateSupplierMutationVariables> {
    override document = UpdateSupplierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveSupplierDocument = gql`
    mutation RemoveSupplier($id: Int!) {
  removeSupplier(id: $id) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveSupplierGQL extends Apollo.Mutation<RemoveSupplierMutation, RemoveSupplierMutationVariables> {
    override document = RemoveSupplierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateUnitDocument = gql`
    mutation CreateUnit($createUnitInput: CreateUnitInput!) {
  createUnit(createUnitInput: $createUnitInput) {
    id
    unit_name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateUnitGQL extends Apollo.Mutation<CreateUnitMutation, CreateUnitMutationVariables> {
    override document = CreateUnitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateUnitDocument = gql`
    mutation UpdateUnit($updateUnitInput: UpdateUnitInput!) {
  updateUnit(updateUnitInput: $updateUnitInput) {
    id
    unit_name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateUnitGQL extends Apollo.Mutation<UpdateUnitMutation, UpdateUnitMutationVariables> {
    override document = UpdateUnitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchStatsDocument = gql`
    query fetchStats($statsFilter: FetchStatsParamsDto!) {
  fetchStats(statsFilter: $statsFilter) {
    valueOfItems
    numberOfItems
    numberOfTransactions
    numberOfExpiringProducts
    numberOfExpiredProducts
    numberOfItemsBelowReorder
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchStatsGQL extends Apollo.Query<FetchStatsQuery, FetchStatsQueryVariables> {
    override document = FetchStatsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchInventoriesDocument = gql`
    query FetchInventories($inventoryFilter: InventoryFilter!) {
  inventories(inventoryFilterArgs: $inventoryFilter) {
    list {
      id
      created
      updated
      product {
        id
        product_name
        reorder_level
        description
        product_categories {
          id
          category_name
          description
        }
      }
      drug_strength
      drug_route
      is_drug
      brand_name
      unit
      store {
        id
        store_name
        store_manager_name
        store_location {
          id
          location_name
        }
      }
      cost_price
      selling_price
      quantity
      expiry_date
      reorder_level
      access_level
    }
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchInventoriesGQL extends Apollo.Query<FetchInventoriesQuery, FetchInventoriesQueryVariables> {
    override document = FetchInventoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchInventoriesV2Document = gql`
    query FetchInventoriesV2($inventoryFilter: InventoryFilterV2!) {
  inventoriesV2(inventoryFilterArgs: $inventoryFilter) {
    list {
      id
      created
      updated
      product {
        id
        product_name
        reorder_level
        description
        product_categories {
          id
          category_name
          description
        }
      }
      drug_strength
      drug_route
      is_drug
      brand_name
      unit
      store {
        id
        store_name
        store_manager_name
        store_location {
          id
          location_name
        }
      }
      cost_price
      selling_price
      quantity
      expiry_date
      reorder_level
      access_level
    }
    pagination {
      page
      total_pages
      total_items
      items_per_page
    }
    status
    message
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchInventoriesV2GQL extends Apollo.Query<FetchInventoriesV2Query, FetchInventoriesV2QueryVariables> {
    override document = FetchInventoriesV2Document;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchSingleItemDocument = gql`
    query FetchSingleItem($id: Int!) {
  inventory(id: $id) {
    id
    tenant_id
    actor_id
    created
    updated
    drug_route
    drug_strength
    is_drug
    brand_name
    unit
    product {
      id
      product_name
      reorder_level
      description
      product_categories {
        id
        category_name
        description
      }
    }
    quantity
    expiry_date
    reorder_level
    selling_price
    cost_price
    barcode
    access_level
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchSingleItemGQL extends Apollo.Query<FetchSingleItemQuery, FetchSingleItemQueryVariables> {
    override document = FetchSingleItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductCategoriesDocument = gql`
    query ProductCategories($productCategoryFilter: ProductCategoryFilter!) {
  productCategories(productCategoryFilterArgs: $productCategoryFilter) {
    product_categories {
      id
      category_name
      description
      category_parent {
        category_name
        description
      }
    }
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductCategoriesGQL extends Apollo.Query<ProductCategoriesQuery, ProductCategoriesQueryVariables> {
    override document = ProductCategoriesDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductCategoryDocument = gql`
    query ProductCategory($id: Int!) {
  productCategory(id: $id) {
    category_name
    description
    category_parent {
      category_name
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductCategoryGQL extends Apollo.Query<ProductCategoryQuery, ProductCategoryQueryVariables> {
    override document = ProductCategoryDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchProductDocument = gql`
    query FetchProduct($id: Int!) {
  product(id: $id) {
    id
    actor_id
    created
    updated
    product_name
    product_categories {
      id
      category_name
    }
    product_cost_price
    product_selling_price
    unit
    number_per_unit
    reorder_level
    image_url
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchProductGQL extends Apollo.Query<FetchProductQuery, FetchProductQueryVariables> {
    override document = FetchProductDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ProductsDocument = gql`
    query Products($productsFilter: ProductFilter!) {
  products(productsFilterArgs: $productsFilter) {
    products {
      id
      product_name
      product_cost_price
      product_selling_price
      unit
      number_per_unit
      reorder_level
      product_categories {
        id
        category_name
      }
      brands {
        id
        name
      }
      created
      updated
      image_url
      description
    }
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ProductsGQL extends Apollo.Query<ProductsQuery, ProductsQueryVariables> {
    override document = ProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchPurchaseOrderDetailDocument = gql`
    query FetchPurchaseOrderDetail($id: Int!) {
  purchaseOrderDetail(id: $id) {
    id
    product {
      id
    }
    quantity
    cost_per_unit
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchPurchaseOrderDetailGQL extends Apollo.Query<FetchPurchaseOrderDetailQuery, FetchPurchaseOrderDetailQueryVariables> {
    override document = FetchPurchaseOrderDetailDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchPurchaseOrderDocument = gql`
    query FetchPurchaseOrder($id: Int!) {
  purchaseOrder(id: $id) {
    id
    tenant_id
    actor_id
    created
    updated
    deletedAt
    supplier {
      id
      supplier_name
      supplier_address
      supplier_email
      supplier_country
      supplier_state
      supplier_city
      supplier_zip_code
      supplier_phone
    }
    order_date
    expected_delivery_date
    note
    shipping_method
    shipping_terms
    instruction
    status
    purchase_order_details {
      id
      quantity
      cost_per_unit
      unit {
        id
        unit_name
      }
      product {
        id
        product_name
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchPurchaseOrderGQL extends Apollo.Query<FetchPurchaseOrderQuery, FetchPurchaseOrderQueryVariables> {
    override document = FetchPurchaseOrderDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchPurchaseOrdersDocument = gql`
    query FetchPurchaseOrders($purchaseOrderFilter: PurchaseOrderFilter!) {
  purchaseOrders(purchaseOrderFilter: $purchaseOrderFilter) {
    purchase_orders {
      id
      tenant_id
      actor_id
      created
      updated
      deletedAt
      supplier {
        id
        supplier_name
      }
      order_date
      expected_delivery_date
      status
      purchase_order_details {
        id
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchPurchaseOrdersGQL extends Apollo.Query<FetchPurchaseOrdersQuery, FetchPurchaseOrdersQueryVariables> {
    override document = FetchPurchaseOrdersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StoreLocationsDocument = gql`
    query StoreLocations($storeLocationFilter: FilterStoreLocationArgs!) {
  storeLocations(filterStoreLocationArgs: $storeLocationFilter) {
    store_locations {
      id
      location_name
      location_address
      location_state
      location_city
      created
      updated
    }
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StoreLocationsGQL extends Apollo.Query<StoreLocationsQuery, StoreLocationsQueryVariables> {
    override document = StoreLocationsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StoreLocationDocument = gql`
    query StoreLocation($id: Int!) {
  storeLocation(id: $id) {
    id
    location_name
    location_address
    location_state
    location_city
    created
    updated
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StoreLocationGQL extends Apollo.Query<StoreLocationQuery, StoreLocationQueryVariables> {
    override document = StoreLocationDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StoreDocument = gql`
    query Store($id: Int!) {
  store(id: $id) {
    id
    actor_id
    created
    updated
    store_name
    store_location {
      id
      location_name
      location_address
    }
    store_manager_name
    store_manager_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StoreGQL extends Apollo.Query<StoreQuery, StoreQueryVariables> {
    override document = StoreDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const StoresDocument = gql`
    query Stores($storesFilter: FilterStoresArgs!) {
  stores(filterStoresArgs: $storesFilter) {
    stores {
      id
      actor_id
      created
      updated
      store_name
      store_location {
        id
        location_name
        location_address
      }
      store_manager_name
      store_manager_id
    }
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class StoresGQL extends Apollo.Query<StoresQuery, StoresQueryVariables> {
    override document = StoresDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SupplierDocument = gql`
    query Supplier($id: Int!) {
  supplier(id: $id) {
    id
    tenant_id
    actor_id
    created
    updated
    deletedAt
    supplier_name
    supplier_address
    supplier_email
    supplier_phone
    supplier_website
    supplier_city
    supplier_country
    supplier_address
    supplier_zip_code
    supplier_state
    bank_details {
      id
      tenant_id
      actor_id
      created
      updated
      deletedAt
      account_name
      account_number
      bank_name
      bank_code
      note
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SupplierGQL extends Apollo.Query<SupplierQuery, SupplierQueryVariables> {
    override document = SupplierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const FetchSupplierDocument = gql`
    query FetchSupplier($id: Int!) {
  supplier(id: $id) {
    id
    tenant_id
    actor_id
    created
    updated
    deletedAt
    supplier_name
    supplier_address
    supplier_email
    supplier_phone
    supplier_website
    supplier_city
    supplier_country
    supplier_address
    supplier_zip_code
    about_supplier
    supplier_state
    bank_details {
      id
      tenant_id
      actor_id
      created
      updated
      deletedAt
      account_name
      account_number
      bank_name
      bank_code
      note
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class FetchSupplierGQL extends Apollo.Query<FetchSupplierQuery, FetchSupplierQueryVariables> {
    override document = FetchSupplierDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const SuppliersDocument = gql`
    query Suppliers($suppliersFilter: SupplierFilter!) {
  suppliersList(suppliersFilter: $suppliersFilter) {
    suppliers {
      id
      tenant_id
      actor_id
      created
      updated
      supplier_name
      supplier_address
      supplier_email
      supplier_phone
      supplier_website
      supplier_city
      supplier_country
      supplier_address
      supplier_zip_code
      about_supplier
      supplier_state
    }
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SuppliersGQL extends Apollo.Query<SuppliersQuery, SuppliersQueryVariables> {
    override document = SuppliersDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TransactionDocument = gql`
    query Transaction($transactionFilter: FilterTransactionInput!) {
  transaction(filterTransactionInput: $transactionFilter) {
    id
    tenant_id
    actor_id
    created
    updated
    deletedAt
    inventory {
      id
      product {
        id
        product_name
        product_cost_price
        product_selling_price
      }
    }
    cost_per_unit
    quantity
    source {
      id
      store_name
      store_manager_name
      store_manager_id
      tenant_id
    }
    destination {
      id
      store_name
      store_manager_name
      store_manager_id
      tenant_id
    }
    created_by_id
    created_by_name
    tx_type
    tx_ref
    tx_status
    sqat
    dqat
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TransactionGQL extends Apollo.Query<TransactionQuery, TransactionQueryVariables> {
    override document = TransactionDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TransactionsDocument = gql`
    query Transactions($transactionsFilter: FilterTransactionsInput!) {
  transactions(filterTransactionsInput: $transactionsFilter) {
    list {
      id
      tenant_id
      actor_id
      created
      updated
      deletedAt
      inventory {
        id
        product {
          id
          product_name
          product_cost_price
          product_selling_price
        }
      }
      cost_per_unit
      quantity
      source {
        id
        store_name
        store_manager_name
        store_manager_id
        tenant_id
      }
      destination {
        id
        store_name
        store_manager_name
        store_manager_id
        tenant_id
      }
      created_by_id
      created_by_name
      tx_type
      tx_ref
      tx_status
      sqat
      dqat
    }
    count
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TransactionsGQL extends Apollo.Query<TransactionsQuery, TransactionsQueryVariables> {
    override document = TransactionsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TransactionsStatisticsDocument = gql`
    query transactionsStatistics($filterTransactionsStatisticsInput: FilterTransactionsStatsInput!) {
  transactionsStatistics(
    filterTransactionsStatisticsInput: $filterTransactionsStatisticsInput
  ) {
    total_number_of_transactions
    total_amount_of_transactions
    expired_items
    expiring_items
    number_of_items_exceeded_reorder_level
    total_value_of_items
    total_items
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TransactionsStatisticsGQL extends Apollo.Query<TransactionsStatisticsQuery, TransactionsStatisticsQueryVariables> {
    override document = TransactionsStatisticsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnitDocument = gql`
    query Unit($id: Int!) {
  unit(id: $id) {
    id
    tenant_id
    actor_id
    created
    updated
    deletedAt
    unit_name
    item_per_unit
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnitGQL extends Apollo.Query<UnitQuery, UnitQueryVariables> {
    override document = UnitDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UnitsDocument = gql`
    query Units($unitsFilter: UnitFilter!) {
  units(unitsFilterArgs: $unitsFilter) {
    list {
      id
      tenant_id
      actor_id
      created
      updated
      deletedAt
      unit_name
      item_per_unit
      description
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UnitsGQL extends Apollo.Query<UnitsQuery, UnitsQueryVariables> {
    override document = UnitsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }