import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TextInputFieldComponent } from './text-input-field.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';



@NgModule({
  declarations: [
    TextInputFieldComponent
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule
  ],
  exports: [TextInputFieldComponent]
})
export class TextInputFieldModule { }
