import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListViewComponent } from './list-view.component';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatTableModule } from '@angular/material/table';
import { TableModule } from '../table/table.module';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';

@NgModule({
  declarations: [ListViewComponent],
  imports: [CommonModule, MatProgressBarModule, TableModule, MatButtonModule, MatPaginatorModule],
  exports: [ListViewComponent],
})
export class ListViewModule {}
